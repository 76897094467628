<template>
  <div v-show="isNL">
    <Head />
    <About />
    <Services />
    <Offers />
    <Contact />
    <Info />
  </div>
  <div v-show="isFR">
    <HeadFR />
    <AboutFR />
    <ServicesFR />
    <OffersFR />
    <ContactFR />
    <InfoFR />
  </div>
</template>
 
<script>
import Head from '@/components/Head.vue';
import About from '@/components/About.vue';
import Services from '@/components/Services.vue';
import Offers from '@/components/Offers.vue';
import Contact from '@/components/Contact.vue';
import Info from '@/components/Info.vue';

import HeadFR from '@/components/HeadFR.vue';
import AboutFR from '@/components/AboutFR.vue';
import ServicesFR from '@/components/ServicesFR.vue';
import OffersFR from '@/components/OffersFR.vue';
import ContactFR from '@/components/ContactFR.vue';
import InfoFR from '@/components/InfoFR.vue';

export default {
  components: {
    Head,
    About,
    Services,
    Offers,
    Contact,
    Info,

    HeadFR,
    AboutFR,
    ServicesFR,
    OffersFR,
    ContactFR,
    InfoFR,
  },
  computed: {
        currentLanguage() {
            return this.$store.getters['languageStore/currentLanguage'];
        },
        isFR(){
          return this.currentLanguage == "FR";
        },
        isNL(){
          return this.currentLanguage == "NL";
        }
    },
};
</script>

<style>
/* Add any home page specific styles here */
</style>
