import { createStore } from 'vuex';
import { mailModule } from './modules/mailStore';
import { languageStore } from './modules/languageStore';

export default createStore({
  modules: {
    mailModule,
    languageStore
  }
});
