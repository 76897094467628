
<template>
  <div class="contact-form">
    <br>
    <div class="row">
      <div class="col-12">
        <span>
          <label v-bind:for="file_id" class="btn btn-primary upload-btn">upload pdf</label>
        </span>
        <span :class="labelClass" style="margin:6px;">{{ fileName }}</span>
        <input v-on:change="chooseFile($event)" v-bind:id="file_id" type="file" v-bind:name="title" style="display:none;">
      </div>
    </div>
    <div class="row">
      <div class="col-12 mt-2">
        <div class="label">Bestanden moeten kleiner zijn dan 15 MB.</div>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  props: ['file_id', 'title'],
  data() {
    return {
      file: null,
    };
  },
  methods: {
    chooseFile(evt) {
      if (evt.target.files[0]) this.file = evt.target.files[0];
    }
  },
  computed: {
    fileName() {
      if (this.file != null)
        return this.file.name;

      return this.title;
    },
    labelClass() {
      if (this.file != null)
        return ""
      return "muted";
    }
  }
};
</script>
  
<style lang="scss" scoped>
.upload-btn {
  border-radius: 2rem;
  font-size: .7rem;
  letter-spacing: normal;
  ;
  text-transform: capitalize;
  padding: 20px 15px !important;
  padding-top: 2px !important;
  height: 20px !important;
  margin-bottom: 1px !important;
  min-width: auto !important;
}

.label {
  font-size: .7rem;
}

.muted {
  opacity: .85;
  font-size: .8rem;
}
</style>
  