<template>
    <header id="head" class="masthead">
        <div class="overlay">
            <div class="container px-4 px-lg-5 d-flex h-100 align-items-center justify-content-center">
                <div class="d-flex justify-content-center">
                    <div class="text-center">
                        <h1 class="text-center mx-auto my-0 text-uppercase">RegiDrive</h1>
                        <h2 class="text-center text-white-50 mt-5 mb-1">Uw Preregistratie Partner: Snel en Betrouwbaar</h2>
                        <button class="btn btn-primary mt-2" @click="redirectToPreRegister">GRATIS OFFERTE</button>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>
  
<script>
export default {
    name: 'Masthead',
    methods: {
        redirectToPreRegister() {
            this.$router.push('/pre-register');
        }
    }
};
</script>
  
<style lang="scss" scoped>
@import '@/assets/styles/variables.scss';

.masthead {
    position: relative;
    width: 100%;
    height: auto;
    min-height: 35rem;
    padding: 15rem 0;
    background: url("@/assets/img/head2.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-size: cover;

    .overlay {
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, .7);

        .container .d-flex.justify-content-center {
            padding: 8rem;
            background: rgba(255, 255, 255, .2);
        }
    }

    h1 {
        font-family: $font-family-special;
        font-size: 2.5rem;
        line-height: 2.5rem;
        letter-spacing: 0.8rem;
        background: linear-gradient(rgba($white, 0.9), rgba($white, 0));
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
        background-clip: text;
    }

    h2 {
        font-size: 1rem;
    }


    @media (min-width: $md) {
        h1 {
            font-size: 4rem;
            line-height: 4rem;
        }
    }

    /* 
        > .container {
        padding-top: 17rem;
        padding-bottom: 12.5rem;
    }*/

    @media (min-width: $lg) {
        height: 100vh;
        /*can remove this*/
        padding: 0;

        h1 {
            font-size: 6.5rem;
            line-height: 6.5rem;
            letter-spacing: 0.8rem;
        }

        h2 {
            font-size: 1.25rem;
        }
    }
}</style>
  