export const languageStore = {
    namespaced: true,
    state: () => ({
      currentLanguage: localStorage.getItem('currentLanguage') || 'NL'
    }),
    getters: {
      currentLanguage: state => state.currentLanguage
    },
    mutations: {
      SET_LANGUAGE(state, newLanguage) {
        state.currentLanguage = newLanguage;
        localStorage.setItem('currentLanguage', newLanguage);
      }
    },
    actions: {
      changeLanguage({ commit }, newLanguage) {
        commit('SET_LANGUAGE', newLanguage);
      }
    }
  };
  