import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import FaqView from '../views/FaqView.vue'
import PreregisterView from '../views/PreregisterView.vue'
import FormSubmittedView from '../views/FormSubmittedView.vue'
import ContactSubmittedView from '../views/ContactSubmittedView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/faq',
    name: 'faq',
    component: FaqView
  },
  {
    path: '/pre-register',
    name: 'pre-register',
    component: PreregisterView
  },
  {
    path: '/formsubmitted',
    name: 'formsubmitted',
    component: FormSubmittedView
  },
  {
    path: '/contactsubmitted',
    name: 'contactsubmitted',
    component: ContactSubmittedView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
