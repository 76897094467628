import { defineRule } from 'vee-validate';

defineRule('valid_chassis_number', (value) => {
  const hasCorrectLength = value.length === 17;
  const hasLetters = /[A-Za-z]/.test(value);
  const hasNumbers = /[0-9]/.test(value);

  if (hasCorrectLength && hasLetters && hasNumbers) {
    return true;
  }
});
