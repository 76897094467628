<template>
    <footer class="footer bg-black small text-center">
        <div class="container px-4 px-lg-5">
            Copyright &copy; {{ currentYear }} RegiDrive
        </div>
    </footer>
</template>
  
<script>

export default {
    name: 'Footer',
    computed: {
        currentYear() {
            return new Date().getFullYear();
        }
    }
};
</script>
  
<style lang="scss" scoped>
@import '@/assets/styles/variables.scss';

.footer {
    height: 5vh;
    width: 100%;
    padding: 1rem 0;
    color: $white;
    background-color: $regi-dark !important;
}
</style>
  