<!-- Form submitted-->
<template>
    <section id="form-submitted" class="form-submitted-section">
        <div class="heading"></div>
        <div class="g-divider"></div>
        <div class="container mt-5 mb-5">
            <div class="row">
                <h4>Uw bericht is goed aangekomen!</h4>
                <div class="pt-5">
                    Hartelijk dank voor uw aanvraag! Uw verzoek is ontvangen en wordt nu verwerkt.
                    Binnenkort ontvangt u een persoonlijke offerte met de prijzen en alle benodigde informatie.
                    We kijken ernaar uit om u van dienst te zijn!
                </div>
            </div>
        <div class="row pt-5">
            <div class="d">
                
                <router-link to="/" class="btn btn-primary">Terug</router-link>
            </div>
        </div>
        </div>

    </section>
</template>

<script>

</script>

<style lang="scss" scoped>
@import '@/assets/styles/variables.scss';

.form-submitted-section {
    min-height: 95vh;

    h5 {
        font-size: 1rem !important;
    }

    .heading {
        height: 20vh;
        background-color: $regi-dark;
        width: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-attachment: scroll;
        background-size: cover;
    }

    p {
        margin-bottom: 1rem;

        &.text-white-50 {
            display: list-item;
            list-style: circle;
            margin-left: 15px;
        }
    }
}
</style>