<template>
    <section id="offers-fr" class="offers-section bg-light">
        <div class="container pb-5 pt-5">
            <div class="row">

                <div class="row">
                    <div class="col-md-8">
                        <h4 class="text-background">Nos Services</h4>
                    </div>
                </div>
            </div>
            <div class="row gx-4 gx-lg-5">
                <div class="col-lg-4 mb-3 mb-lg-0">

                    <div class="card py-4 h-100">
                        <div class="card-body text-center">
                            <h4 class="text-uppercase m-0">RegiView</h4>
                            <hr class="my-4 mx-auto">
                            <div class="text-black-50">
                                <div>
                                    Analyse des données du véhicule
                                </div>
                                <div>
                                    Aperçu des données du véhicule
                                </div>
                                <div>
                                    Pré-vérification pour l’immatriculation sur WebDIV
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 mb-3 mb-lg-0">
                    <div class="card py-4 h-100">
                        <div class="card-body text-center">
                            <h4 class="text-uppercase m-0">RegiDrive</h4>
                            <hr class="my-4 mx-auto">
                            <div class="text-black-50">

                                <div>Préenregistrement/Correction d'un véhicule neuf/occasion importé
</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 mb-3 mb-lg-0">
                    <div class="card py-4 h-100">
                        <div class="card-body text-center">
                            <h4 class="text-uppercase m-0">RegiPro</h4>
                            <hr class="my-4 mx-auto">
                            <div class="text-black-50">
                                <div>
                                    Préenregistrement/Correction d'un véhicule neuf/occasion importé
                                </div>
                                <div>Pré-vérification pour l’immatriculation sur WebDIV</div>
                                <div>Aperçu des données du véhicule et de l’immatriculation</div>
                                <div>Impression du formulaire E705 pour la demande d’immatriculation (nouveau véhicule)</div>
                                <div>Aperçu des données pour d’immatriculation (véhicule d'occasion)</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col text-center">
                    <btn class="btn btn-primary mt-5" @click='redirectToPreRegister'>Devis gratuit</btn>
                </div>
            </div>
        </div>
    </section>
</template>


<script>
export default {
    name: 'Offers',
    methods: {
        redirectToPreRegister() {
            this.$router.push('/pre-register');
        }
    }
};

</script>
  
<style lang="scss" scoped>
.offers-section {
    .text-black-50>div {
        display: list-item;
        list-style: outside;
        margin-left: 15px;
    }
}
</style>
  