<template>
    <section id="offers" class="offers-section bg-light">
        <div class="container pb-5 pt-5">
            <div class="row">

                <div class="row">
                    <div class="col-md-8">
                        <h4 class="text-background">Onze diensten</h4>
                    </div>
                </div>
            </div>
            <div class="row gx-4 gx-lg-5">
                <div class="col-lg-4 mb-3 mb-lg-0">

                    <div class="card py-4 h-100">
                        <div class="card-body text-center">
                            <h4 class="text-uppercase m-0">RegiView</h4>
                            <hr class="my-4 mx-auto">
                            <div class="text-black-50">
                                <div>
                                    Analyse van voertuiggegevens
                                </div>
                                <div>
                                    Overzicht van voertuiggegevens
                                </div>
                                <div>
                                    Pre-verificatie op inschrijving via WebDIV
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 mb-3 mb-lg-0">
                    <div class="card py-4 h-100">
                        <div class="card-body text-center">
                            <h4 class="text-uppercase m-0">RegiDrive</h4>
                            <hr class="my-4 mx-auto">
                            <div class="text-black-50">

                                <div>Preregistratie/Correctie van een ingevoerd nieuw/tweedehands voertuig</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 mb-3 mb-lg-0">
                    <div class="card py-4 h-100">
                        <div class="card-body text-center">
                            <h4 class="text-uppercase m-0">RegiPro</h4>
                            <hr class="my-4 mx-auto">
                            <div class="text-black-50">
                                <div>
                                    Preregistratie/Correctie van een ingevoerd nieuw/tweedehands voertuig
                                </div>
                                <div>Pre-check op inschrijving via WebDIV</div>
                                <div>Overzicht met voertuig en inschrijvingsinformatie</div>
                                <div>Afdruk E705 voor de aanvraag tot inschrijving (nieuw voertuig)</div>
                                <div>Overzicht met inschrijvingsgegevens (tweedehandsvoertuig)</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col text-center">
                    <btn class="btn btn-primary mt-5" @click='redirectToPreRegister'>Gratis offerte</btn>
                </div>
            </div>
        </div>
    </section>
</template>


<script>
export default {
    name: 'Offers',
    methods: {
        redirectToPreRegister() {
            this.$router.push('/pre-register');
        }
    }
};

</script>
  
<style lang="scss" scoped>
.offers-section {
    .text-black-50>div {
        display: list-item;
        list-style: outside;
        margin-left: 15px;
    }
}
</style>
  