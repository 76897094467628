<template>
<div class='container'>
<img src="/img/logo.png" alt="background image"/>

<h4><b>ALGEMENE VOORWAARDEN VOOR DIENSTVERLENERS </b></h4>
<br>
<br>
<p><b>Artikel 1 - Definities </b></p>
<p>1. CL&amp;K Team bv, gevestigd te BRUSSEL, BTW-nummer BE 0729.895.405 wordt in deze  algemene voorwaarden aangeduid als dienstverlener. </p>
<p>2. De wederpartij van dienstverlener wordt in deze algemene voorwaarden aangeduid als opdrachtgever. </p>
<p>3. Partijen zijn dienstverlener en opdrachtgever samen. </p>
<p>4. Met de overeenkomst wordt bedoeld de overeenkomst tot dienstverlening tussen partijen. </p>
<p><b>Artikel 2 - Toepasselijkheid algemene voorwaarden </b></p>
<p>1. Deze voorwaarden zijn van toepassing op alle offertes, aanbiedingen, werkzaamheden, overeenkomsten en leveringen van diensten of goederen door of namens dienstverlener. </p>
<p>2. Afwijken van deze voorwaarden kan alleen als dat uitdrukkelijk én schriftelijk door partijen is overeengekomen. </p>
<p>3. De overeenkomst bevat voor dienstverlener steeds inspanningsverplichtingen, geen resultaatsverplichtingen. </p>
<p><b>Artikel 3 - Betaling </b></p>
<p>1. Declaraties moeten binnen 30 dagen na factuurdatum betaald zijn, tenzij partijen hierover schriftelijk andere afspraken hebben gemaakt of op de declaratie een andere betaaltermijn vermeld is. </p>
<p>2. Betalingen geschieden zonder enig beroep op opschorting of verrekening door overschrijving van het verschuldigde bedrag op het door dienstverlener opgegeven bankrekeningnummer. </p>
<p>3. Betaalt opdrachtgever niet binnen de overeengekomen termijn, dan is hij van rechtswege, zonder dat daarvoor enige aanmaning nodig is, in verzuim. Vanaf dat moment is dienstverlener gerechtigd de verplichtingen op te schorten totdat opdrachtgever aan zijn betalingsverplichtingen heeft voldaan. </p>
<p>4. Blijft opdrachtgever in gebreke, dan zal dienstverlener tot invordering overgaan. De kosten met betrekking tot die invordering komen voor rekening van de opdrachtgever. Wanneer opdrachtgever in verzuim is, is hij naast de hoofdsom tevens 10% rente op het factuurbedrag, buitengerechtelijke incassokosten en overige schade verschuldigd aan dienstverlener. De incassokosten worden berekend aan de hand van het Besluit vergoeding voor buitengerechtelijke incassokosten. </p>
<p> </p>


<p>5. In geval van liquidatie, faillissement, beslag of surseance van betaling van de opdrachtgever zijn de vorderingen van dienstverlener op de opdrachtgever onmiddellijk opeisbaar. </p>
<p>6. Weigert opdrachtgever zijn medewerking aan de uitvoering van de opdracht door dienstverlener, dan is hij nog steeds verplicht de afgesproken prijs aan dienstverlener te betalen. </p>
<p><b>Artikel 4 - Aanbiedingen en offertes </b></p>
<p>1. De aanbiedingen van dienstverlener zijn hooguit 5 dagen geldig, tenzij in het aanbod een andere termijn van aanvaarding is genoemd. Wordt het aanbod niet binnen die gestelde termijn aanvaard, dan vervalt het aanbod. </p>
<p>2. Levertijden in offertes zijn indicatief en geven de opdrachtgever bij overschrijding daarvan geen recht op ontbinding of schadevergoeding, tenzij partijen uitdrukkelijk én schriftelijk anders zijn overeengekomen. </p>
<p>3. Aanbiedingen en offertes gelden niet automatisch voor nabestellingen. Partijen moeten dit uitdrukkelijk én schriftelijk overeenkomen. </p>
<p><b>Artikel 5 - Prijzen </b></p>
<p>1. De op aanbiedingen, offertes en facturen van dienstverlener genoemde prijzen zijn exclusief btw en eventuele andere overheidsheffingen, tenzij uitdrukkelijk anders vermeld. </p>
<p>2. De prijzen van goederen zijn gebaseerd op de op dat moment bekend zijnde kostprijzen. Verhogingen hiervan, die door de dienstverlener niet konden worden voorzien ten tijde van het doen van de aanbieding c.q. het tot stand komen van de overeenkomst, kunnen aanleiding geven tot prijsverhogingen. </p>
<p>3. Ten aanzien van de dienstverlening kunnen partijen bij de totstandkoming van de overeenkomst een vaste prijs overeenkomen. </p>
<p>4. Indien er geen vaste prijs is overeengekomen, kan het tarief met betrekking tot de dienstverlening worden vastgesteld op grond van de werkelijk bestede uren. Het tarief wordt berekend volgens de gebruikelijke uurtarieven van dienstverlener, geldend voor de periode waarin hij de werkzaamheden verricht, tenzij een daarvan afwijkend uurtarief is overeengekomen. </p>
<p>5. Indien geen tarief op grond van de werkelijk bestede uren is afgesproken, wordt voor de dienstverlening een richtprijs afgesproken, waarbij dienstverlener gerechtigd is om hier tot 10% vanaf te afwijken. Indien de richtprijs meer dan 10% hoger uit gaat vallen, dient dienstverlener opdrachtgever tijdig te laten weten waarom een hogere prijs gerechtvaardigd is. Opdrachtgever heeft in dat geval het recht om een deel van de opdracht te laten vervallen, dat boven de richtprijs vermeerderd met 10% uitkomt. </p>
<p> </p>

<p><b>Artikel 6 - Prijsindexering </b></p>
<p>1. De bij het aangaan van de overeenkomst overeengekomen prijzen en uurlonen zijn gebaseerd op het op dat moment gehanteerde prijspeil. Dienstverlener heeft het recht de aan opdrachtgever te berekenen vergoedingen jaarlijks per 1 januari aan te passen. </p>
<p>2. Aangepaste prijzen, tarieven en uurlonen worden zo spoedig mogelijk medegedeeld aan opdrachtgever. </p>
<p><b>Artikel 7 - Informatieverstrekking door opdrachtgever </b></p>
<p>1. Opdrachtgever stelt alle informatie die voor de uitvoering van de opdracht relevant is beschikbaar voor dienstverlener. </p>
<p>2. Opdrachtgever is verplicht alle gegevens en bescheiden, die dienstverlener meent nodig te hebben voor het correct uitvoeren van de opdracht, tijdig en in de gewenste vorm en op de gewenste wijze ter beschikking te stellen. </p>
<p>3. Opdrachtgever staat in voor de juistheid, volledigheid en betrouwbaarheid van de aan dienstverlener ter beschikking gestelde gegevens en bescheiden, ook indien deze van derden afkomstig zijn, voor zover uit de aard van de opdracht niet anders voortvloeit. </p>
<p>4. Opdrachtgever vrijwaart dienstverlener voor elke schade in welke vorm dan ook die voortvloeit uit het niet voldoen aan het in het eerste lid van dit artikel gestelde. </p>
<p>5. Indien en voor zover opdrachtgever dit verzoekt, retourneert dienstverlener de betreffende bescheiden. </p>
<p>6. Stelt opdrachtgever niet, niet tijdig of niet behoorlijk de door dienstverlener verlangde gegevens en bescheiden beschikbaar en loopt de uitvoering van de opdracht hierdoor vertraging op, dan komen de daaruit voortvloeiende extra kosten en extra honoraria voor rekening van opdrachtgever. </p>
<p><b>Artikel 8 - Intrekking opdracht </b></p>
<p>1. Het staat opdrachtgever vrij om de opdracht aan dienstverlener op elk gewenst moment te beëindigen. </p>
<p>2. Wanneer opdrachtgever de opdracht intrekt, is opdrachtgever verplicht het verschuldigde loon en de gemaakte onkosten van dienstverlener te betalen. </p>
<p><b>Artikel 9 - Uitvoering van de overeenkomst </b></p>
<p>1. Dienstverlener voert de overeenkomst naar beste inzicht en vermogen en overeenkomstig de eisen van goed vakmanschap uit. </p>
<p>2. Dienstverlener heeft het recht om werkzaamheden te laten verrichten door derden. </p>
<p>3. De uitvoering geschiedt in onderling overleg en na schriftelijk akkoord en betaling van het eventueel afgesproken voorschot. </p>

<p>4. Het is de verantwoordelijkheid van opdrachtgever dat dienstverlener tijdig kan beginnen aan de opdracht. </p>
<p><b>Artikel 10 - Contractduur opdracht </b></p>
<p>1. De overeenkomst tussen opdrachtgever en dienstverlener wordt aangegaan voor onbepaalde tijd, tenzij uit de aard van de overeenkomst iets anders voortvloeit of partijen uitdrukkelijk én schriftelijk anders zijn overeengekomen. </p>
<p>2. Zijn partijen binnen de looptijd van de overeenkomst voor de voltooiing van bepaalde werkzaamheden een termijn overeengekomen, dan is dit nooit een fatale termijn. Bij overschrijding van deze termijn moet opdrachtgever dienstverlener schriftelijk in gebreke stellen. </p>
<p><b>Artikel 11 - Wijziging van de overeenkomst </b></p>
<p>1. Indien tijdens de uitvoering van de overeenkomst blijkt dat het voor een behoorlijke uitvoering van de opdracht noodzakelijk is om de te verrichten werkzaamheden te wijzigen of aan te vullen, passen partijen tijdig en in onderling overleg de overeenkomst dienovereenkomstig aan. </p>
<p>2. Indien partijen overeenkomen dat de overeenkomst wordt gewijzigd of aangevuld, kan het tijdstip van voltooiing van de uitvoering daardoor worden beïnvloed. Dienstverlener stelt opdrachtgever hiervan zo spoedig mogelijk op de hoogte. </p>
<p>3. Indien de wijziging van of aanvulling op de overeenkomst financiële en/of kwalitatieve consequenties heeft, licht dienstverlener opdrachtgever hierover zo spoedig mogelijk schriftelijk in. </p>
<p>4. Indien partijen een vast honorarium zijn overeengekomen, geeft dienstverlener daarbij aan in hoeverre de wijziging of aanvulling van de overeenkomst een overschrijding van dit honorarium tot gevolg heeft. </p>
<p><b>Artikel 12 - Aansprakelijkheid opdrachtgever </b></p>
<p>1. Ingeval een opdracht wordt verstrekt door meer dan één persoon, is ieder van hen hoofdelijk aansprakelijk voor de bedragen die uit hoofde van die opdracht aan dienstverlener verschuldigd zijn. </p>
<p><b>Artikel 13 - Vrijwaring </b></p>
<p>De opdrachtgever vrijwaart dienstverlener tegen alle aanspraken van derden, die verband houden met de door dienstverlener geleverde goederen en/of diensten. </p>
<p><b>Artikel 14 - Klachtplicht </b></p>
<p>1. Opdrachtgever is verplicht klachten over de verrichte werkzaamheden direct schriftelijk te melden aan dienstverlener. De klacht bevat een zo gedetailleerd mogelijke omschrijving van de tekortkoming, zodat dienstverlener in staat is hierop adequaat te reageren. </p>

<p>2. Een klacht kan er in ieder geval niet toe leiden, dat dienstverlener gehouden kan worden om andere werkzaamheden te verrichten dan zijn overeengekomen. </p>
<p><b>Artikel 15 - Eigendomsvoorbehoud, opschortingsrecht en retentierecht </b></p>
<p>1. De bij opdrachtgever aanwezige zaken en geleverde zaken en onderdelen blijven eigendom van dienstverlener totdat opdrachtgever de gehele afgesproken prijs heeft betaald. Tot die tijd kan dienstverlener zich beroepen op zijn eigendomsvoorbehoud en de zaken terugnemen. </p>
<p>2. Indien de overeengekomen vooruit te betalen bedragen niet of niet op tijd worden voldaan, heeft dienstverlener het recht om de werkzaamheden op te schorten totdat het overeengekomen deel alsnog is voldaan. Er is dan sprake van schuldeisersverzuim. Een verlate levering kan in dat geval niet aan dienstverlener worden tegengeworpen. </p>
<p>3. Dienstverlener is niet bevoegd de onder zijn eigendomsvoorbehoud vallende zaken te verpanden noch op enige andere wijze te bezwaren. </p>
<p>4. Indien zaken nog niet zijn geleverd, maar de overeengekomen voortuitbetaling of prijs niet conform afspraak is voldaan, heeft dienstverlener het recht van retentie. De zaak wordt dan niet geleverd totdat opdrachtgever volledig en conform afspraak heeft betaald. </p>
<p>5. In geval van liquidatie, insolventie of surseance van betaling van opdrachtgever zijn de verplichtingen van opdrachtgever onmiddellijk opeisbaar. </p>
<p><b>Artikel 16 - Intellectueel eigendom </b></p>
<p>1. Tenzij partijen schriftelijk anders zijn overeengekomen, behoudt dienstverlener alle intellectuele absolute rechten (waaronder auteursrecht, octrooirecht, merkenrecht, tekeningen- en modellenrecht, etc.) op alle ontwerpen, tekeningen, geschriften, dragers met gegevens of andere informatie, offertes, afbeeldingen, schetsen, modellen, maquettes, etc. </p>
<p>2. De genoemde intellectuele absolute rechten mogen niet zonder schriftelijke toestemming van dienstverlener worden gekopieerd, aan derden getoond en/of ter beschikking gesteld of op andere wijze worden gebruikt. </p>
<p>3. Opdrachtgever verplicht zich tot geheimhouding van de door dienstverlener aan hem ter beschikking gestelde vertrouwelijke informatie. Onder vertrouwelijke informatie wordt in ieder geval verstaan datgene waarop dit artikel betrekking heeft, alsmede de  bedrijfsgegevens. Opdrachtgever verplicht zich zijn personeel en/of derden die betrokken zijn bij de uitvoering van deze overeenkomst, een schriftelijke geheimhoudingsplicht op te leggen van de strekking van deze bepaling. </p>


<p><b>Artikel 17 - Geheimhouding </b></p>
<p>1. Ieder der partijen houdt de informatie die hij (in welke vorm dan ook) van de andere partij ontvangt en alle andere informatie betreffende de andere partij waarvan hij weet of redelijkerwijs kan vermoeden dat deze geheim of vertrouwelijk is, dan wel informatie waarvan hij kan verwachten dat de verspreiding daarvan de andere partij schade kan berokkenen, geheim en neemt alle nodige maatregelen om te waarborgen dat zijn personeel de genoemde informatie ook geheimhoudt. </p>
<p>2. De in het eerste lid van dit artikel genoemde geheimhoudingsplicht geldt niet voor informatie: </p>
<p>a. die op het moment dat de ontvanger deze informatie ontving al openbaar was of nadien openbaar is geworden zonder een schending door de ontvangende partij van een op hem rustende geheimhoudingsplicht; </p>
<p>b. waarvan de ontvangende partij kan bewijzen dat deze informatie al in zijn bezit was op het moment van verstrekken door de andere partij; </p>
<p>c. die de ontvangende partij van een derde heeft ontvangen waarbij deze derde gerechtigd was deze informatie aan de ontvangende partij te verstrekken  </p>
<p>d. die door de ontvangende partij openbaar wordt gemaakt op grond van een wettelijke plicht. </p>
<p>3. De in dit artikel omschreven geheimhoudingsplicht geldt voor de duur van deze overeenkomst en voor een periode van drie jaar na het eindigen daarvan. </p>
<p><b>Artikel 18 - Toepasselijk recht en bevoegde rechter </b></p>
<p>1. Op iedere overeenkomst tussen partijen is uitsluitend het Belgisch recht van toepassing. </p>
<p>2. De Belgische rechter in het arrondissement waar CL&amp;K Team bv is gevestigd/praktijk houdt/kantoor houdt is exclusief bevoegd om kennis te nemen van eventuele geschillen tussen partijen, tenzij de wet dwingend anders voorschrijft. </p>


</div>
</template>

<style scoped>
.container{
    max-height: 80vh;
    overflow: auto;
}
img{
    height:64px;
}
</style>