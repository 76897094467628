<template>
    <section id="services" class="services-section bg-light">
        <div class="container pt-5">
            <div class="row gx-0 mb-2 mb-lg-5">
                    <h2 class="text-center">Waarom RegiDrive?</h2>
            </div>
        </div>

        <div class="container mt-5 mb-5 pb-5 services">
            <!-- Project Row -->
            <div class="row gx-0 mb-5 mb-lg-0 pt-10">
                <div class="col-lg-6 order-lg-first lg-black">
                    <div class="text-center h-100 project">
                        <div class="d-flex h-100">
                            <div class="project-text w-100 my-auto text-lg-left">
                                <h4>Tijdsbesparing</h4>
                                <p class="mb-0">
                                    Laat ons het administratieve werk doen, zodat u meer tijd heeft voor wat echt belangrijk
                                    is.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="text-center h-100 project">
                        <div class="d-flex h-100">
                            <div class="project-text w-100 my-auto text-lg-right">
                                <h4>Persoonlijke Begeleiding</h4>
                                <p class="mb-0">
                                    Profiteer van onze persoonlijke begeleiding bij elke stap van de registratie
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Project Row -->
            <div class="row gx-0">
                <div class="col-lg-6 order-lg-first">
                    <div class="text-center h-100 project">
                        <div class="d-flex h-100">
                            <div class="project-text w-100 my-auto text-lg-left">
                                <h4>Aanpasbare Oplossingen</h4>
                                <p class="mb-0">
                                    Onze diensten kunnen worden afgestemd op uw specifieke behoeften.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="text-center h-100 project lg-black">
                        <div class="d-flex h-100">
                            <div class="project-text w-100 my-auto text-lg-right">
                                <h4>Duidelijke Communicatie</h4>
                                <p class="mb-0">
                                    Wij houden u op de hoogte en zorgen voor transparante communicatie gedurende het hele
                                    proces.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Project Row -->
            <div class="row gx-0">
                <div class="col-lg-6 order-lg-first lg-black">
                    <div class="text-center h-100 project">
                        <div class="d-flex h-100">
                            <div class="project-text w-100 my-auto text-lg-left">
                                <h4>Gratis Offerte</h4>
                                <p class="mb-0">
                                    Vul ons formulier in en ontvang een vrijblijvende offerte op maat, zonder kosten.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="text-center h-100 project">
                        <div class="d-flex h-100">
                            <div class="project-text w-100 my-auto text-lg-right">
                                <h4>WebDIV Simulatie</h4>
                                <p class="mb-0">
                                    We voeren een simulatie uit op de inschrijving via WebDIV om er zeker van te zijn dat uw
                                    voertuig zonder problemen kan worden ingeschreven.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Project Row -->
            <div class="row gx-0 mb-5 mb-lg-0">
                <div class="col-lg-6 order-lg-first">
                    <div class="text-center h-100 project">
                        <div class="d-flex h-100">
                            <div class="project-text w-100 my-auto text-lg-left">
                                <h4>Vlotte Inschrijving van Nieuwe Voertuigen</h4>
                                <p class="mb-0">
                                    Wij verstrekken uitgebreide voertuiginformatie en de juiste codes om de aanvraag tot
                                    inschrijving vlekkeloos in te vullen door uw makelaar.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 lg-black">
                    <div class="text-center h-100 project">
                        <div class="d-flex h-100">
                            <div class="project-text w-100 my-auto text-lg-left">
                                <h4>Ervaring</h4>
                                <p class="mb-0">
                                    Met een geschiedenis van meer dan een decennium in preregistratie en inschrijving van
                                    voertuigen in België, kunt u bij RegiDrive rekenen op een ervaren partner.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div class="g-divider"></div>

    </section>
</template>


<style lang="scss" scoped>
@import '@/assets/styles/variables.scss';

.services-section {
    .project-text {
        padding: 3rem;
        font-size: 90%;


        @media (min-width: $lg) {
            padding: 5rem;
        }
    }
    
    .featured-text {
        padding: 2rem;

        @media (min-width: $lg) {
            padding: 0 0 0 2rem;
        }
    }
    .img-w {
        @media(min-width:$md) {
            max-height: 350px;
            justify-content: center;
        }
    }

    .card {
        h4 {
            font-size: 1rem !important;
        }
    }

    .project {
        h4 {
            margin: 0 !important;
        }

        &.bg-black {
            background-color: $regi-dark !important;
        }
    }

    .services {
        h4 {
            margin-bottom: 1rem !important;
        }

        @media (min-width:$lg) {
            .lg-black {
                background: $regi-dark;

                h4,
                p {
                    color: $white !important;
                }
            }
        }

        @media (max-width:$lg) {
            .order-lg-first {
                background: $regi-dark;

                h4,
                p {
                    color: $white !important;
                }
            }
        }
    }
}
</style>
  