<template>
    <!-- About-->
    <section id="about-fr" class="text-center about-section">
        <div class="procedure-section bg-light">

            <!-- Side by Side -->
            <div class="container pt-5">
                <div class="row gx-0 pb-lg-5">
                    <div class="col-md-8 d-flex align-items-top">
                        <div class="featured-text text-start">
                            <h4 class="border-bottom pb-2">Pré-enregistrez en toute confiance: <span class="gold">Notre approche dévouée </span></h4>
                            <p>
                                Chez RegiDrive, nous visons à simplifier et accélérer le processus d'immatriculation des véhicules en 
                                Belgique. Nos services spécialisés de préenregistrement vous permettent d'immatriculer votre 
                                véhicule, qu'il soit neuf ou d'occasion, de manière facile et précise. Nous nous occupons de toutes 
                                les étapes préparatoires, y compris le dédouanement et la simulation WebDIV, pour garantir le bon 
                                déroulement de votre immatriculation. Contactez-nous dès aujourd'hui pour découvrir comment 
                                nous pouvons vous aider à réaliser une immatriculation rapide et sans souci.
                            </p>
                            <p>
                                Plus de 10 ans d'expérience en préenregistrement et en immatriculation
                            </p>
                            <p>
                                Avec plus d'une décennie d'expérience en préenregistrement et en immatriculation de véhicules en 
                                Belgique, vous pouvez compter sur RegiDrive comme partenaire expérimenté pour une 
                                immatriculation fluide et sans problème.
                            </p>
                            <p>
                                Découvrez comment nous pouvons vous aider à réaliser une immatriculation sans accroc - contactez-nous dès aujourd'hui !"
                            </p>
                            <div>
                                <btn class="btn btn-primary mt-5" @click="redirectToPreRegister">Devis gratuit</btn>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 d-flex align-items-center justify-content-center">
                        <img class="img-fluid mb-3 mb-lg-0 img-w" src="@/assets/img/speech bubble.png" alt="...">
                    </div>
                </div>
            </div>
        </div>

        <div class="g-divider"></div>

        <!-- Side by Side -->
        <div class="container pt-5">
            <div class="row gx-0 mb-4 mb-lg-5">
                <div class="col-md-4 d-flex align-items-center">
                    <img class="img-fluid mb-3 mb-lg-0" src="@/assets/img/computer.png" alt="...">
                </div>
                <div class="col-md-8 d-flex align-items-top">
                    <div class="featured-text text-start">
                        <h4 class="margin-bottom">Qu’est-ce qu’un préenregistrement?</h4>
                        <p class="mb-0">
                            Le préenregistrement est une étape essentielle pour les véhicules qui ne sont pas encore 
                            immatriculés en Belgique. Au cours de ce processus, toutes les données techniques pertinentes sont 
                            associées au numéro de châssis du véhicule. Ces informations servent de base pour l'immatriculation 
                            ultérieure du véhicule en Belgique.
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="g-divider"></div>

        <div class="container wave explain" id="belangrijke stappen fr">
            <div class="row">
                <div class="col">
                    <h4 class="mb-5">
                        Étapes Importantes
                    </h4>
                </div>
            </div>
            <div class="row">

                <div class="col" style="align-self: center;">
                    <div class="grid-container">

                        <div class="grid-item">
                            <i class="about-icon fa-solid fa-person-military-pointing"></i>
                            <div>
                                <h5 class="gold">Douane</h5>
                                Enregistrement du véhicule auprès des douanes sur le site MyMinfin.
                            </div>
                        </div>

                        <div class="grid-arrow"><i class="fas fa-arrow-right"></i></div>

                        <div class="grid-item">
                            <i class="about-icon fa-solid fa-laptop-file"></i>
                            <div>
                                <h5 class="gold">Préenregistrement</h5>
                                Nous garantissons un enregistrement précise des données de votre véhicule auprès de la DIV.
                            </div>
                        </div>

                        <div class="grid-arrow"><i class="fas fa-arrow-right"></i></div>

                        <div class="grid-item">
                            <i class="about-icon fa-solid fa-list-check"></i>
                            <div>
                                <h5 class="gold">Contrôle technique</h5>
                                Faites inspecter votre véhicule d'occasion pour vente dans un centre de contrôle technique de votre choix.
                            </div>
                        </div>

                        <div class="grid-arrow"><i class="fas fa-arrow-right"></i></div>

                        <div class="grid-item">
                            <i class="about-icon fa-solid fa-road-circle-check"></i>
                            <div>
                                <h5 class="gold">Immatriculation</h5>
                                L’immatriculation de votre véhicule par un courtier/une compagnie d'assurance sur WebDIV/DIV.
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <br />
            <br />
        </div>
        <div class="container mb-5">
            <div class="row mt-5">
                <div class="col-md-12">
                    <p style="font-weight:bold;">
                        Attention : Seul le suivi dans l'ordre ci-dessus permettra à votre courtier d'immatriculer avec succès votre véhicule sur WebDIV.
                    </p>
                </div>
            </div>
        </div>

        <div class="g-divider"></div>

        <div class="container">
            <div class="row pb-5">

                <div class="col-md-4 d-md-block justify-content-center">
                    <img class="img-fluid mb-3 mb-lg-0 img-w" src="@/assets/img/lightbulb.png" alt="...">
                </div>
                <div class="col-md-8">
                    <h4 class="d-flex justify-content-between align-items-center">
                        Bien à Savoir !
                    </h4>
                    <ul class="custom-list">
                        <li>Le préenregistrement commence par un dédouanement réussi, qui pose les bases d'un 
                            immatriculation sans problème.</li>
                        <li>Après le préenregistrement, nous effectuons un contrôle approfondi pour garantir que les 
                            données du véhicule disponibles sont correctement enregistrées à la DIV.</li>
                        <li>Suite à la vérification, nous procédons à une simulation d’immatriculation pour nous assurer 
                            que votre courtier peut immatriculer votre véhicule sur WebDIV sans problème</li>
                        <li>Si votre véhicule ne peut pas être immatriculer sur WebDIV, nous vous fournirons un aperçu 
                            détaillé de la raison et une série claire d'étapes à suivre pour que l’immatriculation réussisse 
                            auprès de la DIV.</li>
                    </ul>
                </div>

            </div>
        </div>
        <div class="g-divider"></div>
        <div class="container">
            <div class="row pb-5">
                <div class="col-md-4 d-md-block justify-content-center">
                    <img class="img-fluid mb-3 mb-lg-0 img-w" src="@/assets/img/network.png" alt="...">
                </div>
                <div class="col-md-8">
                    <h4 class="mb-5"> L'importance des données du véhicule correctes auprès de la DIV et des services fiscaux !</h4>
                    <p>Il est essentiel que la DIV (Direction pour l'Immatriculation des Véhicules) et les services fiscaux 
                        disposent de données précises sur votre véhicule pour le calcul de la TMC (Taxe de Mise en 
                        Circulation) et de la taxe de circulation. C'est pourquoi nous nous assurons que toutes les données du véhicule sont enregistrées de 
                        manière précise et à jour auprès de la DIV, même si votre véhicule est passé au contrôle 
                        technique avant le préenregistrement. Vous pouvez avoir confiance en nos services 
                        pour garantir l'intégrité de vos informations sur le véhicule, vous offrant une tranquillité d'esprit en 
                        sachant que vos données sont correctement enregistrées.
                    </p>
                </div>
            </div>
        </div>
        <div class="g-divider"></div>
    </section>
</template>


<script>
export default {
    name: 'About',
    methods: {
        redirectToPreRegister() {
            this.$router.push('/pre-register');
        }
    }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/variables.scss';

.about-section {
    b {
        color: $gold;
    }

    .custom-list li {
        margin-top: 1rem;

        &::marker {
            content: "➣ ";
        }
    }

    .procedure-section {
        p {
            margin-bottom: 1.5rem;
        }
    }

    .icon-center {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 75%;
    }

    .img-w {
        @media(min-width:$md) {
            max-height: 350px;
            justify-content: center;
        }
    }

    .wave {
        background-image: url("@/assets/img/wave.jpg");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
    }

    .explain {
        .about-icon {
            height: 45px;
            color: $regi-light !important;
            padding-top: 15px;
        }

        .text-justify {
            font-size: .9rem !important;

            h5 {
                font-size: 1rem !important;
            }
        }
    }

    .featured-text {
        padding: 2rem;

        @media (min-width: $lg) {
            padding: 0 0 0 2rem;
        }
    }

    .hide-lg {
        @media(max-width:$lg) {
            display: none !important;
        }
    }

    .grid-container {
        display: grid;
        grid-template-columns: repeat(11, 1fr);
        grid-gap: 10px;
    }

    h5 {
        margin-top: 2rem;
        margin-bottom: 2rem;
        text-align: center;
    }

    .grid-item {
        grid-column: span 2;
        text-align: center;
    }

    .grid-arrow {
        display: flex;
        justify-content: center;
        margin-top: 6rem;
        font-size: 24px;
    }

    @media (max-width: 768px) {
        .grid-container {
            grid-template-columns: 1fr;
        }

        .grid-arrow {
            display: none;
        }
    }
}</style>