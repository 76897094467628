<template>
    <!-- About-->
    <section id="about" class="text-center about-section">
        <div class="procedure-section bg-light">

            <!-- Side by Side -->
            <div class="container pt-5">
                <div class="row gx-0 pb-lg-5">
                    <div class="col-md-8 d-flex align-items-top">
                        <div class="featured-text text-start">
                            <h4 class="border-bottom pb-2">Pre-registreer met Vertrouwen: <span class="gold">Onze Toegewijde
                                    Aanpak</span></h4>
                            <p>
                                Bij RegiDrive streven we naar vereenvoudiging en versnelling van het
                                voertuigregistratieproces
                                in België.
                                Onze gespecialiseerde preregistratiediensten stellen u in staat om uw voertuig, of het nu
                                nieuw
                                of tweedehands is, moeiteloos en nauwkeurig in te schrijven.
                                We verzorgen alle voorbereidende stappen, inclusief douaneafhandeling en WebDIV-simulatie,
                                om
                                ervoor te zorgen dat uw inschrijving soepel verloopt.
                                Neem vandaag nog contact met ons op om te ontdekken hoe wij u kunnen helpen bij een snelle
                                en
                                zorgeloze voertuiginschrijving.
                            </p>
                            <p>
                                Meer dan 10 Jaar Ervaring in Preregistratie en Inschrijving
                            </p>
                            <p>
                                Met een geschiedenis van meer dan een decennium in preregistratie en inschrijving van
                                voertuigen
                                in België, kunt u bij RegiDrive rekenen op een ervaren partner voor een naadloze en
                                probleemloze
                                inschrijving.
                            </p>
                            <p>
                                Ontdek hoe wij u kunnen helpen met een vlotte inschrijving - neem vandaag nog contact met
                                ons
                                op!
                            </p>
                            <div>
                                <btn class="btn btn-primary mt-5" @click="redirectToPreRegister">Gratis offerte</btn>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 d-flex align-items-center justify-content-center">
                        <img class="img-fluid mb-3 mb-lg-0 img-w" src="@/assets/img/speech bubble.png" alt="...">
                    </div>
                </div>
            </div>
        </div>

        <div class="g-divider"></div>

        <!-- Side by Side -->
        <div class="container pt-5">
            <div class="row gx-0 mb-4 mb-lg-5">
                <div class="col-md-4 d-flex align-items-center">
                    <img class="img-fluid mb-3 mb-lg-0" src="@/assets/img/computer.png" alt="...">
                </div>
                <div class="col-md-8 d-flex align-items-top">
                    <div class="featured-text text-start">
                        <h4 class="margin-bottom">Wat is een preregistratie?</h4>
                        <p class="mb-0">
                            Preregistratie is een essentiële stap voor voertuigen die nog niet in België zijn geregistreerd.
                            Tijdens dit proces worden alle relevante technische gegevens gekoppeld aan het chassisnummer van
                            het voertuig.
                            Deze informatie dient als basis voor de verdere inschrijving van het voertuig in België.
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="g-divider"></div>

        <div class="container wave explain" id="belangrijke stappen">
            <div class="row">
                <div class="col">
                    <h4 class="mb-5">
                        Belangrijke stappen
                    </h4>
                </div>
            </div>
            <div class="row">

                <div class="col" style="align-self: center;">
                    <div class="grid-container">

                        <div class="grid-item">
                            <i class="about-icon fa-solid fa-person-military-pointing"></i>
                            <div>
                                <h5 class="gold">Douane</h5>
                                Registratie van het voertuig bij de Douane via Myminfin.
                            </div>
                        </div>

                        <div class="grid-arrow"><i class="fas fa-arrow-right"></i></div>

                        <div class="grid-item">
                            <i class="about-icon fa-solid fa-laptop-file"></i>
                            <div>
                                <h5 class="gold">Preregistratie</h5>
                                Wij verzekeren een accurate registratie van uw voertuiginformatie bij de DIV.
                            </div>
                        </div>

                        <div class="grid-arrow"><i class="fas fa-arrow-right"></i></div>

                        <div class="grid-item">
                            <i class="about-icon fa-solid fa-list-check"></i>
                            <div>
                                <h5 class="gold">Keuring</h5>
                                Uw tweedehandsvoertuig laten inspecteren voor verkoop bij een keuringscentrum van uw keuze.
                            </div>
                        </div>

                        <div class="grid-arrow"><i class="fas fa-arrow-right"></i></div>

                        <div class="grid-item">
                            <i class="about-icon fa-solid fa-road-circle-check"></i>
                            <div>
                                <h5 class="gold">Inschrijving</h5>
                                Inschrijving van uw voertuig door een makelaar/verzekeringsmaatschappij via WebDIV/DIV
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <br />
            <br />
        </div>
        <div class="container mb-5">
            <div class="row mt-5">
                <div class="col-md-12">
                    <p style="font-weight:bold;">
                        Let op: Alleen door de bovenstaande volgorde te volgen, kan uw makelaar uw voertuig succesvol
                        inschrijven via WebDIV.
                    </p>
                </div>
            </div>
        </div>

        <div class="g-divider"></div>

        <div class="container">
            <div class="row pb-5">

                <div class="col-md-4 d-md-block justify-content-center">
                    <img class="img-fluid mb-3 mb-lg-0 img-w" src="@/assets/img/lightbulb.png" alt="...">
                </div>
                <div class="col-md-8">
                    <h4 class="d-flex justify-content-between align-items-center">
                        Goed om te weten!
                    </h4>
                    <ul class="custom-list">
                        <li>Preregistratie begint met een succesvolle douaneafhandeling, wat de basis legt voor een vlotte
                            inschrijving.</li>
                        <li>Na de preregistratie voeren we een grondige controle uit om te verzekeren dat de beschikbare
                            voertuiggegevens correct geregistreerd staan bij de DIV.</li>
                        <li>Na de keuring voeren we een inschrijvingssimulatie uit om ervoor te zorgen dat uw makelaar
                            zonder problemen via WebDIV kan inschrijven.</li>
                        <li>Indien blijkt dat uw voertuig niet online via WebDIV kan worden ingeschreven, voorzien we u van
                            een gedetailleerd overzicht van de reden en een heldere reeks stappen die u moet volgen om de
                            inschrijving succesvol via de DIV te laten verlopen.</li>
                    </ul>
                </div>

            </div>
        </div>
        <div class="g-divider"></div>
        <div class="container">
            <div class="row pb-5">
                <div class="col-md-4 d-md-block justify-content-center">
                    <img class="img-fluid mb-3 mb-lg-0 img-w" src="@/assets/img/network.png" alt="...">
                </div>
                <div class="col-md-8">
                    <h4 class="mb-5"> Belang van Correcte Voertuiggegevens bij DIV en Belastingdiensten!</h4>
                    <p>Het is van essentieel belang dat zowel de <b>DIV (Dienst inschrijving voertuigen) </b>als de
                        belastingdiensten beschikken over nauwkeurige voertuiggegevens voor de berekening van de <b>BIV
                            (Belasting op de inverkeerstelling)</b> en de <b> verkeersbelasting</b>.
                         Daarom zorgen wij ervoor dat alle voertuiggegevens
                         nauwkeurig en up-to-date worden geregistreerd bij de DIV, zelfs als uw voertuig werd gekeurd VOOR de preregistratie.
                        U kunt erop vertrouwen dat onze diensten de integriteit van uw voertuiginformatie waarborgen,
                        waardoor u gemoedsrust heeft in de wetenschap dat uw gegevens correct zijn vastgelegd.
                    </p>
                </div>
            </div>
        </div>
        <div class="g-divider"></div>
    </section>
</template>


<script>
export default {
    name: 'About',
    methods: {
        redirectToPreRegister() {
            this.$router.push('/pre-register');
        }
    }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/variables.scss';

.about-section {
    b {
        color: $gold;
    }

    .custom-list li {
        margin-top: 1rem;

        &::marker {
            content: "➣ ";
        }
    }

    .procedure-section {
        p {
            margin-bottom: 1.5rem;
        }
    }

    .icon-center {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 75%;
    }

    .img-w {
        @media(min-width:$md) {
            max-height: 350px;
            justify-content: center;
        }
    }

    .wave {
        background-image: url("@/assets/img/wave.jpg");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
    }

    .explain {
        .about-icon {
            height: 45px;
            color: $regi-light !important;
            padding-top: 15px;
        }

        .text-justify {
            font-size: .9rem !important;

            h5 {
                font-size: 1rem !important;
            }
        }
    }

    .featured-text {
        padding: 2rem;

        @media (min-width: $lg) {
            padding: 0 0 0 2rem;
        }
    }

    .hide-lg {
        @media(max-width:$lg) {
            display: none !important;
        }
    }

    .grid-container {
        display: grid;
        grid-template-columns: repeat(11, 1fr);
        grid-gap: 10px;
    }

    h5 {
        margin-top: 2rem;
        margin-bottom: 2rem;
        text-align: center;
    }

    .grid-item {
        grid-column: span 2;
        text-align: center;
    }

    .grid-arrow {
        display: flex;
        justify-content: center;
        margin-top: 6rem;
        font-size: 24px;
    }

    @media (max-width: 768px) {
        .grid-container {
            grid-template-columns: 1fr;
        }

        .grid-arrow {
            display: none;
        }
    }
}</style>