<template>
<div class="container">
    <div vlink="blue" link="blue">
        <img src="/img/logo.png" alt="background image"/>
        <h3><b>Privacy Policy </b></h3>
        <p><b>Privacy policy</b> voor CL&amp;K Team, eigenaar van <a href="http://www.regidrive.be/">www.RegiDrive.be</a></p>
        
        <p><b>1) Waarborgen Privacy</b> </p>
        <p>Onze website geeft veel om uw privacy. Wij verwerken daarom uitsluitend gegevens die wij <br/>nodig hebben voor onze dienstverlening en gaan zorgvuldig om met de informatie die wij <br/>over u en uw gebruik van onze diensten hebben verzameld. Wij stellen uw gegevens nooit <br/>voor commerciële doelstellingen ter beschikking aan derden. </p>
        <p><b>2) Toestemming</b> </p>
        <p>Door de informatie en de diensten op www.RegiDrive.be te gebruiken, gaat u akkoord met <br/>onze privacy policy en de voorwaarden die wij hierin hebben opgenomen. </p>
        <p><b>3) Vragen</b> </p>
        <p>Als u meer informatie wilt ontvangen, of vragen hebt over de privacy policy van CL&amp;K Team <br/>en specifiek www.RegiDrive.be, kun u ons benaderen via e-mail. Ons e-mailadres is <br/>Info@regidrive.be. </p>
        <p><b>4) Monitoren gedrag bezoeker</b> </p>
        <p>www.RegiDrive.be maakt gebruik van verschillende technieken om bij te houden wie de <br/>website bezoekt, hoe deze bezoeker zich op de website gedraagt en welke pagina’s worden <br/>bezocht. Dat is een gebruikelijke manier van werken voor websites omdat het informatie <br/>oplevert op die bijdraagt aan de kwaliteit van de gebruikerservaring. De informatie die we, via <br/>cookies, registreren, bestaat uit onder meer IP-adressen, het type browser en de bezochte <br/>startpagina’s. </p>
        <p><b>5) Gebruik van cookies</b> </p>
        <p>www.RegiDrive.be plaatst cookies bij bezoekers. Dat doen we om informatie te verzamelen <br/>over de pagina’s die gebruikers op onze website bezoeken, om bij te houden hoe vaak <br/>bezoekers terug komen en om te zien welke pagina’s het goed doen op de website. Ook <br/>houden we bij welke informatie de browser deelt. </p>
        <p><b>6) Cookies uitschakelen</b> </p>
        <p>U kunt er voor kiezen om cookies uit te schakelen. Dat doet u door gebruik te maken de <br/>mogelijkheden van uw browser. U vindt meer informatie over deze mogelijkheden op de <br/>website van de aanbieder van uw browser. </p>
        <p> </p>
    </div>
    <br>
    <hr>
    <br>
</div>
</template>

<style scoped>
.container{
    max-height: 80vh;
    overflow: auto;
}
    img {    
        height: 64px;
    }
</style>