<template>
    <!-- Entrepreneur Fields -->
    <div class="row">
        <div class="col-md-5 col-lg-4">
            <label for="ondernemingsnummer">Ondernemingsnummer *</label>
            <Field class="form-control" name="ondernemingsnummer" type="ondernemingsnummer" rules="required" />
            <div class="row error-section">
                <ErrorMessage class="col error-message" name="ondernemingsnummer" />
            </div>
        </div>
        <div class="col-md-5 col-lg-4">
            <label for="onderneming">Naam onderneming *</label>
            <Field class="form-control" name="onderneming" type="onderneming" rules="required" />
            <div class="row error-section">
                <ErrorMessage class="col error-message" name="onderneming" />
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <label for="gemeente">Adres</label>
            <Field class="form-control" name="adres" type="adres" />
            <div class="row error-section">
                <ErrorMessage class="col error-message" name="adres" />
            </div>
        </div>
        <div class="col-md-2 col-lg-1">
            <label for="housenr">Huisnummer</label>
            <input name="huisnr" class="form-control" required />
        </div>
    </div>
    <div class="row">
        <div class="col-md-3 col-lg-2">        
            <label for="postcode">Postcode</label>
            <Field class="form-control" name="postcode" type="postcode"  />
            <div class="row error-section">
                <ErrorMessage class="col error-message" name="postcode" />
            </div>
        </div>
        <div class="col-md-6">
            <label for="gemeente">Gemeente</label>
            <Field class="form-control" name="gemeente" type="gemeente"/>
            <div class="row error-section">
                <ErrorMessage class="col error-message" name="gemeente" />
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <label for="contactpersoon">Contactpersoon *</label>
            <Field class="form-control" name="contactpersoon" type="contactpersoon" rules="required" />
            <div class="row error-section">
                <ErrorMessage class="col error-message" name="contactpersoon" />
            </div>
        </div>
        <div class="col-md-6">
            <label for="telephone">Telefoon</label>
            <input name="telephone" class="form-control" />
        </div>
    </div>
</template>

<script>
import { Field, Form, ErrorMessage } from 'vee-validate';
export default {
    components: {
        Field,
        Form,
        ErrorMessage
    },
    name: 'Entrepreneur'
}
</script>

