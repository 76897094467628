<template>
    <section id="contact-fr" class="contact-section pb-5">
        <div class="g-divider"></div>
        <div class="container pt-5">
            <h2>Contactez-nous</h2>
            <p>Avez-vous des questions ou avez besoin d'aide ? N'hésitez pas à nous contacter.</p>

            <Form class="mt-5" :action="formAction" method="POST" enctype="multipart/form-data">
                <div class="contact-form">
                    <input type="hidden" name="_subject" value="RegiDrive contact request">
                    <!-- <input type="hidden" name="_captcha" value="true">
                    <input type="hidden" name="_template" value="table">
                    <input type="text" name="_honey" style="display:none">
                    <input type="hidden" name="_url" :value="url">
                    <input type="hidden" name="_next" v-bind:value="redirectLocation"> -->

                    <div class="row">
                        <div class="offset-lg-2 col-md-6 col-lg-4">
                            <label for="naam">Nom *</label>
                            <Field class="form-control" name="naam" type="naam" rules="required" />
                            <div class="row error-section">
                                <ErrorMessage class="col error-message" name="naam" />
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-4">
                            <label for="voornaam">Prénom *</label>
                            <Field class="form-control" name="voornaam" type="voornaam" rules="required" />
                            <div class="row error-section">
                                <ErrorMessage class="col error-message" name="voornaam" />
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="offset-lg-2 col-md-6 col-lg-4">
                            <label for="email">E-mail *</label>
                            <Field class="form-control" name="email" type="email" rules="required|email" />
                            <div class="row error-section">
                                <ErrorMessage class="col error-message" name="email" />
                            </div>
                        </div>

                        <div class="col-md-6 col-lg-4">
                            <label for="telephone">Téléphone</label>
                            <input type="text" name="telephone" class="form-control">
                        </div>

                        <div class="offset-lg-2 col-lg-8">
                            <label for="message">Message *</label>
                            <Field as="textarea" class="form-control" name="message" type="message" rules="required" />
                            <div class="row error-section">
                                <ErrorMessage class="col error-message" name="message" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="offset-lg-2 col">
                        <button type="submit" class="btn btn-primary">Envoyer</button>
                    </div>
                </div>
            </Form>
        </div>
    </section>
</template>

<script>
import { Field, Form, ErrorMessage } from 'vee-validate';
import { required } from '@vee-validate/rules';
export default {
    components: {
        Field,
        Form,
        ErrorMessage,
        required
    },
    data() {

    },
    computed: {
        redirectLocation() {
            return window.location.origin + '/contactsubmitted';
        },
        formAction() {
            return this.$store.getters.formAction;
        },        
        url() {
            return this.$store.getters.url;
        }
    }
}
</script>

<style lang="scss" scoped></style>