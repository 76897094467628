<template>
<div class='container'>
<img src="/img/logo.png" alt="background image"/>

<h4><b>CONDITIONS GÉNÉRALES POUR LES PRESTATAIRES DE SERVICES</b></h4>
    <br>

<!-- Article 1 -->
<p><b>Article 1 - Définitions</b></p>
<p>1. CL&K Team srl située à BRUXELLES numéro de TVA BE0729.895.405 est désignée comme le prestataire de services dans les présentes conditions générales. </p>
<p>2. Le cocontractant du prestataire de services est désigné dans les présentes conditions générales sous le nom de client. </p>
<p>3. Les parties sont ensemble prestataire de services et client. </p>
<p>4. L'accord désigne l'accord de prestation de services entre les parties. </p>

<!-- Article 2 -->
<p><b>Article 2 – Applicabilité des conditions générales</b></p>
<p>1. Ces conditions s'appliquent à tous les devis, offres, travaux, accords et livraisons de services ou de biens par ou au nom du prestataire de services. </p>
<p>2. Une dérogation à ces conditions n'est possible que si cela a été expressément convenu par écrit par les parties. </p>
<p>3. Le contrat contient toujours des obligations de moyens pour le prestataire de services et non des obligations de résultats. </p>

<!-- Article 3 -->
<p><b>Article 3 – Paiement</b></p>
<p>1. Les factures doivent être payées dans les 30 jours suivant la date de facturation à moins que les parties n'aient conclu d'autres accords écrits à ce sujet ou qu'un délai de paiement différent soit indiqué sur la facture. </p>
<p>2. Les paiements s'effectuent sans aucun recours à la suspension ou au règlement par virement du montant dû au numéro de compte bancaire indiqué par le prestataire. </p>
<p>3. Si le client ne paie pas dans le délai convenu il sera en demeure de plein droit sans qu'aucun rappel ne soit nécessaire. A partir de ce moment le prestataire est en droit de suspendre ses obligations jusqu'à ce que le client ait rempli ses obligations de paiement. </p>
<p>4. Si le client reste en demeure le prestataire procédera au recouvrement. Les frais liés à cette collecte seront à la charge du client. Si le client est en défaut outre le montant principal il doit également au prestataire 10 % d'intérêts sur le montant de la facture les frais de recouvrement extrajudiciaires et autres dommages et intérêts. Les frais de recouvrement sont calculés sur la base du décret relatif à l'indemnisation des frais de recouvrement extrajudiciaires. </p>
<p>5. En cas de liquidation, faillite, saisie ou suspension de paiement du client les créances du prestataire sur le client sont immédiatement exigibles et exigibles. </p>
<p>6. Si le client refuse de coopérer à l'exécution de la mission par le prestataire de services il est néanmoins tenu de payer le prix convenu au prestataire de services. </p>

<!-- Article 4 -->
<p><b>Article 4 – Offres et devis</b></p>
<p>1. Les offres du prestataire de services sont valables pendant 5 jours maximum sauf si un délai d'acceptation différent est indiqué dans l'offre. Si l'offre n'est pas acceptée dans le délai imparti, l'offre deviendra caduque.</p>
<p>2. Les délais de livraison indiqués dans les offres sont indicatifs et ne donnent pas droit au client à une résiliation ou à une indemnisation en cas de dépassement, sauf si les parties en ont expressément convenu autrement par écrit.</p>
<p>3. Les offres et devis ne s'appliquent pas automatiquement aux commandes répétées. Les parties doivent le faire expressément et par écrit.</p>

<!-- Article 5 -->
<p><b>Article 5 – Tarifs</b></p>
<p>1. Les prix indiqués sur les offres, devis et factures du prestataire de services s'entendent hors TVA et tout autre prélèvement gouvernemental, sauf indication contraire.</p>
<p>2. Les prix des marchandises sont basés sur les prix de revient connus à ce moment-là. Des augmentations de ce montant qui n'auraient pas pu être prévues par le prestataire au moment de la soumission de l'offre ou de la conclusion du contrat peuvent entraîner des augmentations de prix.</p>
<p>3. En ce qui concerne les services, les parties peuvent convenir d'un prix fixe lors de la conclusion du contrat.</p>
<p>4. Si aucun prix forfaitaire n'a été convenu, le tarif relatif à la prestation peut être déterminé sur la base des heures effectivement effectuées. Le tarif est calculé selon les tarifs horaires habituels du prestataire, applicables pour la période au cours de laquelle il effectue les travaux, sauf accord sur un tarif horaire différent.</p>
<p>5. Si aucun tarif n'a été convenu sur la base des heures réellement travaillées, un prix indicatif sera convenu pour la prestation, auquel le prestataire de services est en droit de s'écarter jusqu'à 10 %. Si le prix indicatif est supérieur de plus de 10 %, le prestataire de services doit informer le client en temps utile des raisons pour lesquelles un prix plus élevé est justifié. Dans ce cas, le Client a le droit d'annuler une partie de la commande qui dépasse le prix indicatif majoré de 10 %.</p>

<!-- Article 6 -->
<p><b>Article 6 – Indexation des prix</b></p>
<p>1. Les prix et rémunération horaire convenus lors de la conclusion du contrat sont basés sur le niveau de prix en vigueur à ce moment-là. Le prestataire a le droit d'ajuster annuellement les frais à facturer au client à compter du 1er janvier.</p>
<p>2. Les prix, tarifs et rémunération horaire ajustés seront communiqués au client dans les plus brefs délais.</p>

<!-- Article 7 -->
<p><b>Article 7 – Fourniture d'informations par le client</b></p>
<p>1. Le Client met toutes les informations utiles à l'exécution de la mission à la disposition du prestataire de services.</p>
<p>2. Le Client est tenu de mettre à disposition toutes les informations et tous les documents dont le prestataire estime avoir besoin pour la bonne exécution de la mission, en temps opportun et sous la forme et de la manière souhaitées.</p>
<p>3. Le Client garantit l'exactitude, l'exhaustivité et la fiabilité des données et documents mis à la disposition du prestataire, même s'ils proviennent de tiers, sauf disposition contraire de la nature de la mission.</p>
<p>4. Le Client garantit le prestataire contre tout dommage sous quelque forme que ce soit résultant du non-respect des dispositions du premier alinéa du présent article.</p>
<p>5. Si et dans la mesure où le client le demande, le prestataire de services lui retournera les documents pertinents.</p>
<p>6. Si le client ne fournit pas les informations et documents requis par le prestataire de services ou ne le fait pas à temps ou correctement et que l'exécution de la mission en est retardée, les frais et honoraires supplémentaires qui en résultent seront supportés par le client.</p>

<!-- Article 8 -->
<p><b>Article 8 – Retrait de mission</b></p>
<p>1. Le client est libre de mettre fin à tout moment à la mission auprès du prestataire de services.</p>
<p>2. Si le client retire la mission, il est tenu de payer le salaire dû et les dépenses engagées par le prestataire.</p>

<!-- Article 9 -->
<p><b>Article 9 - Exécution du contrat</b></p>
<p>1. Le prestataire de services exécutera le contrat au mieux de ses connaissances et de ses capacités et conformément aux exigences de bonne exécution.</p>
<p>2. Le prestataire de services a le droit de faire exécuter des travaux par des tiers.</p>
<p>3. L'exécution a lieu d'un commun accord et après accord écrit et paiement de toute avance convenue.</p>
<p>4. Il est de la responsabilité du client que le prestataire puisse commencer la mission à temps.</p>



<!-- Article 10 -->
<p><b>Article 10 - Durée du contrat de mission</b></p>
<p>1. Le contrat entre le client et le prestataire de services est conclu pour une durée indéterminée sauf si la nature du contrat en dispose autrement ou si les parties en ont expressément convenu autrement par écrit.</p>
<p>2. Si les parties ont convenu d'un délai pour l'achèvement de certains travaux pendant la durée du contrat, il ne s'agit jamais d'un délai strict. En cas de dépassement de ce délai, le client devra mettre en demeure par écrit le prestataire.</p>

<!-- Article 11 -->
<p><b>Article 11 - Modifications du contrat</b></p>
<p>1. Si au cours de l'exécution du contrat il apparaît qu'il est nécessaire de modifier ou de compléter les travaux à effectuer pour la bonne exécution de la mission, les parties adapteront le contrat en conséquence en temps opportun et d'un commun accord.</p>
<p>2. Si les parties conviennent que l'accord sera modifié ou complété, le délai d'exécution de l'exécution peut en être affecté. Le prestataire en informera le client dans les plus brefs délais.</p>
<p>3. Si la modification ou l'ajout du contrat a des conséquences financières et/ou qualitatives, le prestataire de services en informera le client par écrit dans les plus brefs délais.</p>
<p>4. Si les parties ont convenu d'un montant fixe, le prestataire de services indiquera dans quelle mesure la modification ou l'ajout au contrat entraînera un dépassement de ce montant.</p>

<!-- Article 12 -->
<p><b>Article 12 - Responsabilité du Client</b></p>
<p>1. Si une mission est assurée par plusieurs personnes, chacune d’elles est solidairement responsable des sommes dues au prestataire au titre de cette mission.</p>

<!-- Article 13 -->
<p><b>Article 13 – Indemnisation</b></p>
<p>Le client garantit le prestataire contre toutes réclamations de tiers liées aux biens et/ou services fournis par le prestataire.</p>

<!-- Article 14 -->
<p><b>Article 14 - Obligation de plainte</b></p>
<p>1. Le client est tenu de signaler immédiatement par écrit au prestataire de services toute réclamation concernant le travail effectué. La plainte contient une description du manquement aussi détaillée que possible afin que le prestataire puisse y répondre de manière adéquate.</p>
<p>2. Une réclamation ne peut en aucun cas conduire le prestataire à être obligé d'effectuer des travaux autres que ceux convenus.</p>

<!-- Article 15 -->
<p><b>Article 15 - Réserve de propriété droit de suspension et droit de rétention</b></p>
<p>1. Les marchandises présentes chez le client ainsi que les marchandises et pièces livrées restent la propriété du prestataire jusqu'à ce que le client ait payé la totalité du prix convenu. D'ici là, le prestataire peut invoquer sa réserve de propriété et reprendre la marchandise.</p>
<p>2. Si les montants convenus à payer d'avance ne sont pas payés ou ne sont pas payés à temps, le prestataire de services a le droit de suspendre les travaux jusqu'à ce que la partie convenue soit payée. Il y a alors défaut du créancier. Dans ce cas, un retard de livraison ne pourra être retenu contre le prestataire.</p>
<p>3. Le prestataire n'est pas autorisé à mettre en gage ou à grever d'une autre manière les objets tombant sous sa réserve de propriété.</p>
<p>4. Si les marchandises n'ont pas encore été livrées mais que l'acompte ou le prix convenu n'a pas été payé comme convenu, le prestataire de services a un droit de rétention. L'article ne sera alors livré que lorsque le client aura payé intégralement et comme convenu.</p>
<p>5. En cas de liquidation, d'insolvabilité ou de suspension de paiement du client, les obligations du client sont immédiatement exigibles.</p>


<!-- Article 16 -->
<p><b>Article 16 – Propriété intellectuelle</b></p>
<p>1. Sauf accord écrit contraire des parties, le prestataire de services conserve tous les droits intellectuels absolus (y compris le droit d'auteur, le droit des brevets, le droit des marques, les droits sur les dessins et modèles, etc.) sur tous les modèles, dessins, écrits, supports contenant des données ou autres informations, citations, images, croquis, maquettes, modèles, etc.</p>
<p>2. Les droits intellectuels absolus mentionnés ci-dessus ne peuvent être copiés, montrés à des tiers, et/ou mis à disposition ou utilisés de toute autre manière sans l'autorisation écrite du prestataire de services.</p>
<p>3. Le Client s'engage à préserver la confidentialité des informations confidentielles mises à sa disposition par le prestataire. Les informations confidentielles désignent en tout état de cause celles auxquelles se rapporte cet article ainsi que les données de l'entreprise. Le Client s'engage à imposer une obligation écrite de confidentialité à son personnel et/ou aux tiers impliqués dans la mise en œuvre du présent accord au regard de la portée de cette disposition.</p>

<!-- Article 17 -->
<p><b>Article 17 – Confidentialité</b></p>
<p>1. Chacune des parties conservera les informations qu'elle reçoit (sous quelque forme que ce soit) de l'autre partie et toutes les autres informations concernant l'autre partie qu'elle sait ou peut raisonnablement soupçonner d'être secrètes ou confidentielles ou dont elle peut s'attendre à ce qu'elles soient secrètes ou confidentielles que sa diffusion pourrait causer un préjudice à l'autre partie secret et prend toutes les mesures nécessaires pour que son personnel garde également confidentielles lesdites informations.</p>
<p>2. L'obligation de confidentialité visée au premier alinéa du présent article ne s'applique pas aux informations: a. qui étaient déjà publiques au moment où le destinataire a reçu cette information ou qui est devenue publique par la suite sans violation par la partie destinataire d'une obligation de confidentialité à laquelle elle est soumise; b. lorsque la partie destinataire peut prouver que ces informations étaient déjà en sa possession au moment de leur fourniture par l'autre partie; c. que le destinataire a reçu d'un tiers ce tiers étant autorisé à fournir ces informations au destinataire; d. qui est rendu public par la partie destinataire sur la base d'une obligation légale.</p>
<p>3. L'obligation de confidentialité décrite dans cet article s'applique pendant la durée du présent accord et pendant une période de trois ans après sa résiliation.</p>

<!-- Article 18 -->
<p><b>Article 18 – Loi applicable et tribunal compétent</b></p>
<p>1. Le droit Belge s'applique exclusivement à tout accord entre les parties.</p>
<p>2. Le tribunal Belge du lieu où CL&K Team srl est établie/exerce ses activités/siège est seul compétent pour connaître de tout litige entre les parties, sauf disposition contraire de la loi.</p>

</div>
</template>

<style scoped>
.container{
    max-height: 80vh;
    overflow: auto;
}
img{
    height:64px;
}
</style>