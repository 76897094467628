<template>
    <!-- Faq-->
    <section id="faq" class="faq-section">
        <div class="heading"></div>
        <div class="g-divider"></div>
        <div class="container mt-5 mb-5">
            <div v-show="isNL" class="row justify-content-center">
                <h4>FAQ</h4>
                <br><br><br>
                <div class="col-12 pb-3">
                    <div class="accordion" id="accordionFAQ">
                        <div class="accordion-item">
                            <h5 class="accordion-header" id="headingOne">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                    Is preregistreren verplicht?
                                </button>
                            </h5>
                            <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne"
                                data-bs-parent="#accordionFAQ">
                                <div class="accordion-body">
                                    <p>Ja. het is niet mogelijk om een voertuig in te schrijven als het niet is
                                        geregistreerd bij de DIV.</p>
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item">
                            <h5 class="accordion-header" id="headingTwo">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    Welke voertuigen kunnen jullie preregistreren?
                                </button>
                            </h5>
                            <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                                data-bs-parent="#accordionFAQ">
                                <div class="accordion-body">
                                    <p>Wij bieden preregistratiediensten voor alle soorten nieuwe en tweedehands voertuigen,
                                        met uitzondering van landbouwvoertuigen. Voor oldtimers geldt er een beperking, maar
                                        dit hangt af van de beschikbare informatie over het voertuig.</p>
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item">
                            <h5 class="accordion-header" id="headingThree">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    Kunnen jullie de dedouanering uitvoeren?
                                </button>
                            </h5>
                            <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                                data-bs-parent="#accordionFAQ">
                                <div class="accordion-body">
                                    <p>Ja, op voorwaarde dat er een geldige volmacht van de eigenaar is verkregen. Voor
                                        verdere details en aanvullende informatie kunt u het beste contact met ons opnemen.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item">
                            <h5 class="accordion-header" id="headingFour">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                    Ik heb mijn voertuig gekeurd voor de preregistratie, wat doe ik nu?
                                </button>
                            </h5>
                            <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour"
                                data-bs-parent="#accordionFAQ">
                                <div class="accordion-body">
                                    <p>Indien uw voertuig al gekeurd werd voordat de preregistratie plaatsvond, is
                                        inschrijving via WebDIV niet langer mogelijk. In dit geval wordt de inschrijving
                                        verwerkt door de DIV.</p>
                                    <p>We kunnen u echter nog steeds van dienst zijn door correcties aan te brengen in de
                                        beschikbare voertuiggegevens die zijn vastgelegd tijdens de keuring. In de meeste
                                        gevallen zijn deze gegevens onvolledig.</p>
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item">
                            <h5 class="accordion-header" id="headingFive">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                    Ik heb zelf mijn preregistratie uitgevoerd en heb fouten gemaakt, kunnen jullie verder
                                    helpen?
                                </button>
                            </h5>
                            <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive"
                                data-bs-parent="#accordionFAQ">
                                <div class="accordion-body">
                                    <p>Een nauwkeurige preregistratie is van cruciaal belang voor een succesvolle
                                        inschrijving van uw voertuig. Zolang uw voertuig nog niet is ingeschreven, kunnen
                                        wij u nog steeds van dienst zijn door de geregistreerde voertuiggegevens te
                                        analyseren en corrigeren.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-show="isFR" class="row justify-content-center">
                <h4>FAQ</h4>
                <br><br><br>
                <div class="col-12 pb-3">
                    <div class="accordion" id="accordionFAQ">
                        <div class="accordion-item">
                            <h5 class="accordion-header" id="headingOne">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                    Est-ce obligatoire de faire un préenregistrement?

                                </button>
                            </h5>
                            <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne"
                                data-bs-parent="#accordionFAQ">
                                <div class="accordion-body">
                                    <p>Oui, il n'est pas possible d’immatriculer un véhicule s'il n'est pas enregistré auprès de la DIV</p>
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item">
                            <h5 class="accordion-header" id="headingTwo">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    Quels types de véhicules pouvez-vous pré-enregistrer ?
                                </button>
                            </h5>
                            <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                                data-bs-parent="#accordionFAQ">
                                <div class="accordion-body">
                                    <p>Nous proposons des services de préenregistrement pour tous les types de véhicules neufs et 
d'occasion, à l'exception des véhicules agricoles. Pour les voitures anciennes, il y a une restriction, 
mais cela dépend des informations disponibles sur le véhicule.</p>
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item">
                            <h5 class="accordion-header" id="headingThree">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    Pouvez-vous effectuer la dédouanement ?
                                </button>
                            </h5>
                            <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                                data-bs-parent="#accordionFAQ">
                                <div class="accordion-body">
                                    <p>Oui, à condition d'obtenir une procuration valide du propriétaire. Pour plus de détails et 
                                        d'informations supplémentaires, il est préférable de nous contacter
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item">
                            <h5 class="accordion-header" id="headingFour">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                    J'ai fait inspecter mon véhicule par le contrôle technique avant le préenregistrement, que dois-je 
                                    faire maintenant ?
                                </button>
                            </h5>
                            <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour"
                                data-bs-parent="#accordionFAQ">
                                <div class="accordion-body">
                                    <p>Si votre véhicule a déjà été inspecté par le contrôle technique avant le préenregistrement, 
                                        l’immatriculation sur WebDIV n'est plus possible. Dans ce cas, l’immatriculation doit est traitée par la 
                                        DIV.
                                    </p>
                                    <p>Cependant, nous pouvons toujours vous aider en apportant des corrections aux données disponibles 
                                        sur le véhicule enregistré lors de l'inspection. Dans la plupart des cas, ces données sont 
                                        incomplètes.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item">
                            <h5 class="accordion-header" id="headingFive">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                    J'ai effectué moi-même mon préenregistrement et j'ai fait des erreurs, pouvez-vous m'aider ?
                                </button>
                            </h5>
                            <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive"
                                data-bs-parent="#accordionFAQ">
                                <div class="accordion-body">
                                    <p>
                                        Un préenregistrement précis est essentielle pour l’immatriculation de votre véhicule. Tant 
que votre véhicule n'est pas encore immatriculé, nous pouvons toujours vous aider en 
analysant et corrigeant les données du véhicule enregistrées.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default{
computed: {
        currentLanguage() {
            return this.$store.getters['languageStore/currentLanguage'];
        },
        isFR(){
        return this.currentLanguage == "FR";
        },
        isNL(){
        return this.currentLanguage == "NL";
        }
    }
}
</script>


<style lang="scss" scoped>@import '@/assets/styles/variables.scss';

.faq-section {
    min-height: 95vh;

    h5 {
        margin-top: 0 !important;

    }

    button.accordion-button {
        font-size: 1.2rem;
        box-shadow: none;
        outline: none;
        background: white;
        color:$gold !important;
        margin-top: 0;
        font-weight: 500;

    }

    p {
        font-size: .9rem;
        color: $regi_light;
    }
}</style>