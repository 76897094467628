<template>
    <div class="row">
        <label v-if="isNL">Type Voertuig *</label>
        <label v-if="isFR">Type de véhicule *</label>
        <div class="radio-buttons">
            <div class="form-check form-check-inline">
                <Field name="type voertuig" class="form-check-input" type="radio" value="Auto/Bestelwagen/Trekker" v-model="typeVehicle"
                    rules="required" />
                <label v-if="isNL">Auto/Bestelwagen/Trekker</label>
                <label v-if="isFR">Voiture/Fourgonnette/Camion</label>

            </div>
            <div class="form-check form-check-inline">
                <Field name="type voertuig" class="form-check-input" type="radio" value=" Moto/Bromfiets/Speedpedelec" v-model="typeVehicle"
                    rules="required" />
                <label v-if="isNL"> Moto/Bromfiets/Speedpedelec</label>
                <label v-if="isFR"> Moto/Cyclomoteur/Speed Pedelec</label>
            </div>
            <div class="form-check form-check-inline">
                <Field name="type voertuig" class="form-check-input" type="radio" value="Aanhangwagen" v-model="typeVehicle"
                    rules="required" />
                <label v-if="isNL">Aanhangwagen</label>
                <label v-if="isFR">Remorque</label>
            </div>
            <div class="form-check form-check-inline">
                <Field name="type voertuig" class="form-check-input" type="radio" value="Andere" v-model="typeVehicle"
                    rules="required" />
                <label v-if="isNL">Andere</label>
                <label v-if="isFR">Autres</label>
            </div>
            <div class="row error-section">
                <ErrorMessage class="col error-message" name="type voertuig" />
            </div>
        </div>
    </div>
</template>


<script>
import { Field, ErrorMessage } from 'vee-validate';

export default {
    components: {
        Field,
        ErrorMessage
    },
    computed: {
        currentLanguage() {
            return this.$store.getters['languageStore/currentLanguage'];
        },
        isFR(){
          return this.currentLanguage == "FR";
        },
        isNL(){
          return this.currentLanguage == "NL";
        }
    },
    data() {
        return {
            typeVehicle: ''
        };
    }
}
</script>
