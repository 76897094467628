<template>
    <div class="row">
        <label v-if="isNL">Is het Douanevignet “E705” aangevraagd *</label>
        <label v-if="isFR">La vignette douanière « E705 » a-t-elle été demandée *</label>
        <div class="radio-buttons">
            <div class="form-check form-check-inline">
                <Field name="douanevignet" class="form-check-input" type="radio" value="ja"
                    v-model="douanevignet" rules="required" />
                    <label v-if="isNL">Ja</label>
                    <label v-if="isFR">Oui</label>

            </div>
            <div class="form-check form-check-inline">
                <Field name="douanevignet" class="form-check-input" type="radio" value="nee"
                    v-model="douanevignet" rules="required" />
                <label v-if="isNL">Nee</label>
                <label v-if="isFR">Non</label>
            </div>
            <div class="row error-section">
                <ErrorMessage class="col error-message" name="douanevignet" />
            </div>
        </div>
    </div>
</template>

<script>
import { Field, ErrorMessage } from 'vee-validate';

export default {
    components: {
        Field,
        ErrorMessage
    }, 
     computed: {
        currentLanguage() {
            return this.$store.getters['languageStore/currentLanguage'];
        },
        isFR(){
          return this.currentLanguage == "FR";
        },
        isNL(){
          return this.currentLanguage == "NL";
        }
    },
    data() {
        return {
            douanevignet: '',
        };
    }
}
</script>
