<template>
    <!-- Particulier Fields -->
    <div class="row">
        <div class="col-md-5 col-lg-4">
            <label for="naam">Nom *</label>
            <Field class="form-control" name="naam" type="naam" rules="required" />
            <div class="row error-section">
                <ErrorMessage class="col error-message" name="naam" />
            </div>
        </div>
        <div class="col-md-5 col-lg-4">
            <label for="voornaam">Prénom *</label>
            <Field class="form-control" name="voornaam" type="voornaam" rules="required" />
            <div class="row error-section">
                <ErrorMessage class="col error-message" name="voornaam" />
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <label for="adres">Adresse</label>
            <Field class="form-control" name="adres" type="adres" />
            <div class="row error-section">
                <ErrorMessage class="col error-message" name="adres" />
            </div>
        </div>
        <div class="col-md-2 col-lg-2">
            <label for="housenr">Numéro</label>
            <input name="housenr" class="form-control" />
        </div>
    </div>
    <div class="row">
        <div class="col-md-3 col-lg-2">
            <label for="postcode">Code postal
</label>
            <Field class="form-control" name="postcode" type="postcode" />
            <div class="row error-section">
                <ErrorMessage class="col error-message" name="postcode" />
            </div>
        </div>
        <div class="col-md-6">
            <label for="gemeente">Commune</label>
            <Field class="form-control" name="gemeente" type="gemeente"/>
            <div class="row error-section">
                <ErrorMessage class="col error-message" name="gemeente" />
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <label for="email">Email *</label>
            <Field class="form-control" name="email" type="email" rules="required|email" />
            <div class="row error-section">
                <ErrorMessage class="col error-message" name="email" />
            </div>
        </div>
        <div class="col-md-6">
            <label for="telephone">Téléphone</label>
            <input name="telephone" class="form-control" />
        </div>
    </div>
</template>

<script>
import { Field, Form, ErrorMessage } from 'vee-validate';
import { required } from '@vee-validate/rules';
export default {

    components: {
        Field,
        Form,
        ErrorMessage, 
        required
    },
    name: 'Particulier'
}
</script>
