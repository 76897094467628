<template>
    <NavMenu v-show="isNL" /> 
    <NavMenuFR v-show="isFR" />
    <router-view></router-view>
    <Footer></Footer>
</template>

<script>
import NavMenu from './components/Menu/NavMenu.vue';
import NavMenuFR from './components/Menu/NavMenu-FR.vue';
import Footer from '@/components/Footer.vue';

export default {
  name: 'App',
  components: {
    NavMenu,
    NavMenuFR,
    Footer
  },
  computed: {
  currentLanguage() {
    const language = this.$store.getters['languageStore/currentLanguage'];
    return language;
  },
  isFR() {
    const isFR = this.currentLanguage == "FR";
    return isFR;
  },
  isNL() {
    const isNL = this.currentLanguage == "NL";
    return isNL;
  }
},

};
</script>
