
<template>
  <div v-if="show" class="modal" style="display:block;" >
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <slot v-if="isNL" name="header">Privacy beleid</slot>
          <slot v-if="isFR" name="header">Politique de confidentialité</slot>
          <button type="button" class="close btn" @click="$emit('close')">&times;</button>
        </div>
        <div class="modal-body">
          <slot name="body"></slot>

          <Privacy    v-if="isNL"/>
          <PrivacyFR  v-if="isFR"/>
        </div>
        <div class="modal-footer">
          <slot name="footer">
            <button class="btn btn-primary" @click="$emit('close')">OK</button>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script setup>
import { ref, computed, defineProps, defineEmits } from 'vue';
import Privacy from '@/components/Preregister/PrivacyPolicy.vue';
import PrivacyFR from '@/components/Preregister/PrivacyPolicyFR.vue';
import { useStore } from 'vuex';

// Define props
const props = defineProps({
  show: Boolean
});

// Access the store
const store = useStore();

// Computed properties
const currentLanguage = computed(() => store.getters['languageStore/currentLanguage']);
const isFR = computed(() => currentLanguage.value === "FR");
const isNL = computed(() => currentLanguage.value === "NL");

</script>

<style scoped>

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>