<template>
<div class="container">
    <div vlink="blue" link="blue">
        <img src="/img/logo.png" alt="background image"/>
        <h3><b>Politique de confidentialité</b></h3>
        <p> pour CL&amp;K Team, propriétaire de <a href="http://www.regidrive.be/">www.RegiDrive.be</a></p>

        <p><b>1)  Garantie de la confidentialité  </b></p>
        <p>Notre site Web attache une grande importance à votre vie privée. Nous ne traitons donc <br/>que les données dont nous avons besoin pour notre prestation de services et nous traitons <br/>soigneusement les informations que nous avons recueillies sur vous et votre utilisation de <br/>nos services. Nous ne mettons jamais vos données à disposition de tiers à des fins <br/>commerciales. </p>
        <p><b>2)  Consentement  </b></p>
        <p>En utilisant les informations et les services sur www.RegiDrive.be, vous acceptez notre <br/>politique de confidentialité et les conditions que nous y avons incluses. </p>
        <p><b>3)  Questions </b></p>
        <p> Si vous souhaitez obtenir plus d'informations ou si vous avez des questions sur la <br/>politique de confidentialité de CL&amp;K Team et en particulier de www.RegiDrive.be, vous <br/>pouvez nous contacter par e-mail. Notre adresse e-mail est Info@regidrive.be. </p>
        <p><b>4)  Surveillance du comportement des visiteurs </b></p>
        <p> www.RegiDrive.be utilise différentes techniques pour suivre les visiteurs du site, <br/>comprendre comment ces visiteurs se comportent sur le site et quelles pages sont <br/>consultées. Il s'agit d'une pratique courante sur les sites Web car elle fournit des <br/>informations qui contribuent à la qualité de l'expérience utilisateur. Les informations que <br/>nous enregistrons via les cookies comprennent notamment les adresses IP, le type de <br/>navigateur et les pages d'accueil visitées. </p>
        <p><b>5)  Utilisation de cookies </b></p>
        <p> www.RegiDrive.be place des cookies chez les visiteurs. Nous le faisons pour collecter <br/>des informations sur les pages que les utilisateurs consultent sur notre site Web, pour <br/>suivre la fréquence des visites et pour voir quelles pages fonctionnent bien sur le site. <br/>Nous surveillons également les informations partagées par le navigateur. </p>
        <p><b>6)  Désactivation des cookies </b></p>
        <p> Vous pouvez choisir de désactiver les cookies en utilisant les fonctionnalités de votre <br/>navigateur. Vous trouverez plus d'informations sur ces options sur le site Web du <br/>fournisseur de votre navigateur.&#34; </p>
        <p> </p>
    </div>
    <br>
    <hr>
    <br>
</div>
</template>

<style scoped>
.container{
    max-height: 80vh;
    overflow: auto;
}
    img {    
        height: 64px;
    }
</style>