<template>
    <div class="row">
        <label>Wat kunnen wij voor u doen *</label>
        <div class="radio-buttons">
            <div class="form-check form-check-inline">
                <Field name="typeService " class="form-check-input" type="radio" value="RegiView" v-model="typeService"
                    rules="required" />
                <label>RegiView</label>

            </div>
            <div class="form-check form-check-inline">
                <Field name="typeService" class="form-check-input" type="radio" value="RegiDrive" v-model="typeService"
                    rules="required" />
                <label>RegiDrive</label>
            </div>
            <div class="form-check form-check-inline">
                <Field name="typeService" class="form-check-input" type="radio" value="RegiPro" v-model="typeService"
                    rules="required" />
                <label>RegiPro</label>
            </div>
            <div class="row error-section">
                <ErrorMessage class="col error-message" name="typeService" />
            </div>
        </div>
    </div>
</template>


<script>
import { Field, ErrorMessage } from 'vee-validate';

export default {
    components: {
        Field,
        ErrorMessage
    },
    data() {
        return {
            typeService: ''
        };
    }
}
</script>
