<template>
<section id="info" class="info-section pb-5" style="background:#545454">
    <div class="container px-4 px-lg-5">
        <div class="row gx-4 gx-lg-5">
            <div class="col-lg-4 mb-3 mb-lg-0">
                <div class="card py-4 h-100">
                    <div class="card-body text-center">
                        <i class="fas fa-map-marked-alt text-primary mb-2"></i>
                       
                        <h4 class="text-uppercase m-0">ENTREPRISE</h4>

                        <hr class="my-4 mx-auto">
                        <div class="small text-black-50 text-center">
                            <div class="text-center">BE 0729.895.405</div>
                            <div class="text-center">Proposé par CL&K Team</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 mb-3 mb-lg-0">
                <div class="card py-4 h-100">
                    <div class="card-body text-center">
                        <i class="fas fa-envelope text-primary mb-2"></i>
                        <h4 class="text-uppercase m-0">Email</h4>
                        <hr class="my-4 mx-auto">
                        <div class="small text-black-50 text-center">
                            <a href="mailto:info@regidrive.be">info@regidrive.be</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 mb-3 mb-lg-0">
                <div class="card py-4 h-100">
                    <div class="card-body text-center">
                        <i class="fas fa-mobile-alt text-primary mb-2"></i>
                        <h4 class="text-uppercase m-0">NUMÉRO DE TÉLÉPHONE</h4>
                        <hr class="my-4 mx-auto">
                        <div class="small text-black-50 text-center">
                            <div class="small text-black-50 text-center">
                                Du Lu - Ve de 9h à 18h :</div>
                              <a href="tel:+32471836920">+32 471 83 69 20</a>
                           
                        </div>  
                        
                        <div class="small text-black-50 text-center">
                            
                            <a href="https://wa.me/32471836920"><i class="fab fa-whatsapp"></i></a></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
</template>
<script>
export default {
    name: 'Info'
};
</script>
  
<style lang="scss" scoped>
@import '@/assets/styles/variables.scss';

.info-section {
    padding-top: 5rem;
}
.fa-whatsapp {
    height: 2rem;
    padding:10px;
    color:#25D366;
}
</style>
  