<template>
    <div class="row">
        <label v-if="isNL">Is het voertuig gekeurd *</label>
        <label v-if="isFR">Le véhicule a-t-il été inspecté *</label>
        <div class="radio-buttons">
            <div class="form-check form-check-inline">
                <Field name="voertuiggekeurd" class="form-check-input" type="radio" value="ja" v-model="voertuiggekeurd"
                    rules="required" />
                    <label v-if="isNL">Ja</label>
                    <label v-if="isFR">Oui</label>
            </div>
            <div class="form-check form-check-inline">
                <Field name="voertuiggekeurd" class="form-check-input" type="radio" value="nee" v-model="voertuiggekeurd"
                    rules="required" />
                    <label v-if="isNL">Nee</label>
                    <label v-if="isFR">Non</label>
            </div>
            <div class="row error-section">
                <ErrorMessage class="col error-message" name="voertuiggekeurd" />
            </div>
        </div>
        <!-- <div v-if="voertuiggekeurd === 'ja'">
            <div class="row">
                <div class="row">
                    <div class="col-md-3">
                        <label>Datum keuring via kalender *</label>
                        <input type="date" name="datum keuring" class="form-control" required>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="voertuiggekeurd === 'nee'">
            <div class="row">
                <div class="row">
                    <div class="col-md-3">
                        <label>Wanneer heeft u een afspraak bij de keuring?</label>
                        <input type="date" name="datum afspraak keuring" class="form-control">
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</template>


<script>
import { Field, ErrorMessage } from 'vee-validate';

export default {
    components: {
        Field,
        ErrorMessage
    },
    computed: {
        currentLanguage() {
            return this.$store.getters['languageStore/currentLanguage'];
        },
        isFR(){
          return this.currentLanguage == "FR";
        },
        isNL(){
          return this.currentLanguage == "NL";
        }
    },
    data() {
        return {
            voertuiggekeurd: '',
        };
    }
}
</script>
