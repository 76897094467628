<template>
    <nav id="mainNav" class="navbar navbar-expand-lg navbar-light fixed-top">
        <div class="container px-4">
            <router-link to="/" class="navbar-brand logo"></router-link>
            <button class="navbar-toggler navbar-toggler-right" type="button" data-bs-toggle="collapse"
                data-bs-target="#navbarResponsive">
                Menu
                <i class="fas fa-bars"></i>
            </button>
            <div id="navbarResponsive" class="collapse navbar-collapse">
                <ul class="navbar-nav ms-auto">
                    <li class="nav-item" v-if="isHomePage">
                        <a href="javascript:void(0)" class="nav-link" @click="scrollToSection('head-fr')">Accueil</a>
                    </li>
                    <li class="nav-item" v-if="isHomePage">
                        <a href="javascript:void(0)" class="nav-link" @click="scrollToSection('belangrijke stappen fr')">Procédures d'enregistrement</a>
                    </li>
                    <li class="nav-item" v-if="isHomePage">
                        <a href="javascript:void(0)" class="nav-link" @click="scrollToSection('services-fr')">Nos services</a>
                    </li>
                    <li class="nav-item" v-if="isHomePage">
                        <a href="javascript:void(0)" class="nav-link" @click="scrollToSection('contact-fr')">Contact</a>
                    </li>

                    <li class="nav-item" v-if="!isHomePage">
                        <router-link to="/" class="nav-link redirect">Accueil</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/faq" class="nav-link redirect">Faq</router-link>
                    </li> 
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
                            data-bs-toggle="dropdown" aria-expanded="false">
                            {{ currentLanguage }}
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                            <li><a class="dropdown-item" href="#" @click="changeLanguage('NL')">NL</a></li>
                            <li><a class="dropdown-item" href="#" @click="changeLanguage('FR')">FR</a></li>
                        </ul>
                    </li>                   
                </ul>
            </div>
        </div>
    </nav>
</template>

<script>
export default {   
    computed: {
        isHomePage() {
            return this.$route.path === '/';
        },
        currentLanguage() {
            return this.$store.getters['languageStore/currentLanguage'];
        }
    },
    methods: {
        scrollToSection(sectionId) {
            const element = document.getElementById(sectionId);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 
        changeLanguage(language) {
            this.$store.dispatch('languageStore/changeLanguage', language);
        }
    }
};
</script>


<style lang="scss" scoped>
@import '@/assets/styles/variables.scss';

#mainNav {
    min-height: 3.5rem;
    background-color: $white;

    .navbar-toggler {
        font-size: 80%;
        padding: 0.75rem;
        color: $primary;
        border: 1px solid $primary;

        &:focus {
            outline: none;
        }
    }

    .logo {
        background-image: url("@/assets/logo.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        width: 120px;
        height: 60px;
        display: inline-block;
    }

    .languages {
        li .dropdown-item {
            cursor: pointer;
        }

        li.active .dropdown-item {
            background: $gold;
            color: $white;
        }
    }

    .nav-link {
        cursor: pointer;
    }

    .navbar-brand {
        cursor: pointer;
        color: $black;
        font-weight: 700;
        padding: 0.9rem 0;
    }

    @media (min-width: $lg) {
        background-color: $white;

        .navbar-brand {
            color: $black;
        }

        .nav-link {
            color: $black;
            padding: 1.5rem 1.5rem 1.25rem;
            border-bottom: 0.25rem solid transparent;

            &:hover {
                color: $primary;
            }

            &:active {
                color: darken($primary, 15%);
            }

            &.active {
                color: $primary;
                outline: none;
                border-bottom: 0.25rem solid $primary;
            }
        }
    }
}
</style>