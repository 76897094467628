
const mailModule = {
    state: {
        emailHost: 'https://formspree.io/f/',
        location: 'xpzvaoln',
        url: 'https://www.regidrive.be/' 
    },
    getters: {
        formAction: (state) => {
            return state.emailHost + state.location;
        },
        url: (state) => {
            return state.url;
        }
    }
}

export { mailModule };