<template>
    <div class="row">
        <label v-if="isNL">Bent u in het bezit van het COC (gelijkvormigheidsattest) *</label>
        <label v-if="isFR">Etes-vous en possession du COC (certificat de conformité) *</label>
        <div class="radio-buttons">
            <div class="form-check form-check-inline">
                <Field name="gelijkvormigheidsattest" class="form-check-input" type="radio" value="Ja" v-model="conformityCertificate"
                    rules="required" />
                    <label v-if="isNL">Ja</label>
                    <label v-if="isFR">Oui</label>

            </div>
            <div class="form-check form-check-inline">
                <Field name="gelijkvormigheidsattest" class="form-check-input" type="radio" value="Nee" v-model="conformityCertificate"
                    rules="required" />
                <label v-if="isNL">Nee</label>
                <label v-if="isFR">Non</label>
            </div>
            <div class="row error-section">
                <ErrorMessage class="col error-message" name="gelijkvormigheidsattest" />
            </div>
        </div>
    </div>
</template>


<script>
import { Field, ErrorMessage } from 'vee-validate';

export default {
    components: {
        Field,
        ErrorMessage
    },
    computed: {
        currentLanguage() {
            return this.$store.getters['languageStore/currentLanguage'];
        },
        isFR(){
          return this.currentLanguage == "FR";
        },
        isNL(){
          return this.currentLanguage == "NL";
        }
    },
    data() {
        return {
            conformityCertificate: ''
        };
    }
}
</script>