<template>
    <section id="services-fr" class="services-section bg-light">
        <div class="container pt-5">
            <div class="row gx-0 mb-2 mb-lg-5">
                    <h2 class="text-center">Pourquoi RegiDrive?</h2>
            </div>
        </div>

        <div class="container mt-5 mb-5 pb-5 services">
            <!-- Project Row -->
            <div class="row gx-0 mb-5 mb-lg-0 pt-10">
                <div class="col-lg-6 order-lg-first lg-black">
                    <div class="text-center h-100 project">
                        <div class="d-flex h-100">
                            <div class="project-text w-100 my-auto text-lg-left">
                                <h4>Gain de temps</h4>
                                <p class="mb-0">
                                    Laissez-nous faire le travail administratif, afin que vous ayez plus de temps pour ce qui est vraiment 
important
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="text-center h-100 project">
                        <div class="d-flex h-100">
                            <div class="project-text w-100 my-auto text-lg-right">
                                <h4>Accompagnement personnalisé</h4>
                                <p class="mb-0">
                                    Bénéficiez de notre accompagnement personnalisé à chaque étape de l’enregistrement.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Project Row -->
            <div class="row gx-0">
                <div class="col-lg-6 order-lg-first">
                    <div class="text-center h-100 project">
                        <div class="d-flex h-100">
                            <div class="project-text w-100 my-auto text-lg-left">
                                <h4>Solutions personnalisables</h4>
                                <p class="mb-0">
                                    Nos services peuvent être adaptés à vos besoins spécifiques.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="text-center h-100 project lg-black">
                        <div class="d-flex h-100">
                            <div class="project-text w-100 my-auto text-lg-right">
                                <h4>Communication claire</h4>
                                <p class="mb-0">
                                    Vous pouvez demander gratuitement un devis sur la base des documents de votre véhicule.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Project Row -->
            <div class="row gx-0">
                <div class="col-lg-6 order-lg-first lg-black">
                    <div class="text-center h-100 project">
                        <div class="d-flex h-100">
                            <div class="project-text w-100 my-auto text-lg-left">
                                <h4>Devis gratuit</h4>
                                <p class="mb-0">
                                    Remplissez notre formulaire et recevez un devis personnalisé gratuitement.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="text-center h-100 project">
                        <div class="d-flex h-100">
                            <div class="project-text w-100 my-auto text-lg-right">
                                <h4>Simulation WebDIV</h4>
                                <p class="mb-0">
                                    Nous effectuons une simulation d'immatriculation sur WebDIV pour nous assurer que votre véhicule peut être immatriculer sans problème.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Project Row -->
            <div class="row gx-0 mb-5 mb-lg-0">
                <div class="col-lg-6 order-lg-first">
                    <div class="text-center h-100 project">
                        <div class="d-flex h-100">
                            <div class="project-text w-100 my-auto text-lg-left">
                                <h4>Immatriculation fluide des véhicules neufs</h4>
                                <p class="mb-0">
                                    Nous fournissons des informations détaillées sur le véhicule et les codes corrects afin que la 
demande d'immatriculation puisse être complétée parfaitement par votre courtier.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 lg-black">
                    <div class="text-center h-100 project">
                        <div class="d-flex h-100">
                            <div class="project-text w-100 my-auto text-lg-left">
                                <h4>Expérience</h4>
                                <p class="mb-0">
                                    Avec plus d'une décennie d'expérience dans le préenregistrement et l'immatriculation des véhicules 
                                    en Belgique, vous pouvez compter sur un partenaire expérimenté chez RegiDrive.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div class="g-divider"></div>

    </section>
</template>


<style lang="scss" scoped>
@import '@/assets/styles/variables.scss';

.services-section {
    .project-text {
        padding: 3rem;
        font-size: 90%;


        @media (min-width: $lg) {
            padding: 5rem;
        }
    }
    
    .featured-text {
        padding: 2rem;

        @media (min-width: $lg) {
            padding: 0 0 0 2rem;
        }
    }
    .img-w {
        @media(min-width:$md) {
            max-height: 350px;
            justify-content: center;
        }
    }

    .card {
        h4 {
            font-size: 1rem !important;
        }
    }

    .project {
        h4 {
            margin: 0 !important;
        }

        &.bg-black {
            background-color: $regi-dark !important;
        }
    }

    .services {
        h4 {
            margin-bottom: 1rem !important;
        }

        @media (min-width:$lg) {
            .lg-black {
                background: $regi-dark;

                h4,
                p {
                    color: $white !important;
                }
            }
        }

        @media (max-width:$lg) {
            .order-lg-first {
                background: $regi-dark;

                h4,
                p {
                    color: $white !important;
                }
            }
        }
    }
}
</style>
  