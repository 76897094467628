<template>
    <section id="pre-register" class="preregister-section pb-5">
        <div class="heading"></div>
        <div class="g-divider"></div>
        <div class="container mb-5">
            <Form class="mt-5" :action="formAction" method="POST" enctype="multipart/form-data">
                <input type="hidden" name="_subject" value="RegiDrive preregistratie">
                <!-- <input type="hidden" name="_captcha" value="true">
                <input type="hidden" name="_url" :value="url">
                <input type="hidden" name="_template" value="table">
                <input type="text" name="_honey" style="display:none">
                <input type="hidden" name="_next" v-bind:value="redirectLocation"> -->
                
                <!-- Radio Buttons for Type Selection -->
                <div class="row">
                    <div class="col">
                        <div class="radio-buttons">
                            <div class="form-check form-check-inline">
                                <input type="radio" id="particulier" class="form-check-input" value="Particulier"
                                    v-model="selectedType">
                                <label v-if="isNL" for="particulier">Particulier</label>
                                <label v-if="isFR" for="particulier">Particulier</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input type="radio" id="entrepreneur" class="form-check-input" value="Entrepreneur"
                                    v-model="selectedType">
                                <label v-if="isNL" for="entrepreneur">Onderneming</label>
                                <label v-if="isFR" for="entrepreneur">Entreprise</label>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Particulier Fields -->
                <div v-if="selectedType === 'Particulier'">
                    <Particulier    v-if="isNL"/>
                    <ParticulierFR  v-if="isFR"/>
                </div>

                <!-- Entrepreneur Fields -->
                <div v-if="selectedType === 'Entrepreneur'">
                    <Entrepreneur    v-if="isNL"/>
                    <EntrepreneurFR  v-if="isFR"/>
                </div>
                <hr />
                <div v-if="isNL" class="col-md-5 col-lg-4">
                    <label  for="chassisnummer">Chassisnummer *</label>
                    <Field class="form-control" name="chassisnummer" type="text" rules="required|valid_chassis_number" />
                    <div class="row error-section">
                        <ErrorMessage class="col error-message" name="chassisnummer" />
                    </div>
                </div>
                <div v-if="isFR" class="col-md-5 col-lg-4">
                    <label for="chassisnummer">Numéro de châssis *</label>
                    <Field class="form-control" name="chassisnummer" type="text" rules="required|valid_chassis_number" />
                    <div class="row error-section">
                        <ErrorMessage class="col error-message" name="chassisnummer" />
                    </div>
                </div>
                <!-- Radio Buttons-->
                <TypeVehicle />
                <RegistrationCertificate />
                <ConformityCertificate />
                
                <div v-if="isNL" class="row mb-4">
                    <div class="col-md-5 col-lg-4">
                        <label for="land">Land van herkomst *</label>
                        <Field name="land" as="select" class="form-control" type="text" rules="required" v-model="selectedCountry">
                            <option disabled value="">Selecteer een land</option>
                            <option v-for="country in euCountries" :key="country" :value="country">{{ country }}</option>
                        </Field>
                        <ErrorMessage name="land" class="col error-message" />
                    </div>            
                </div>

                <div v-if="isFR" class="row mb-4">
                    <div class="col-md-5 col-lg-4">
                        <label for="land">Pays d'origine *</label>
                        <Field name="land" as="select" class="form-control" type="text" rules="required" v-model="selectedCountry">
                            <option disabled value="">Sélectionner un pays</option>
                            <option v-for="country in euCountriesFR" :key="country" :value="country">{{ country }}</option>
                        </Field>
                        <ErrorMessage name="land" class="col error-message" />
                    </div>            
                </div>

                <div class="row mb-4">
                    <div class="col-md-3">
                        <label  v-if="isNL" for="datum-inschrijving">Datum eerste inschrijving *</label>
                        <label  v-if="isFR" for="datum-inschrijving">Date de première immatriculation *</label>
                        <Field id="datum-inschrijving" name="datumInschrijving" type="date" class="form-control" rules="required" v-model="datumInschrijving" />
                        <ErrorMessage name="datumInschrijving" class="col error-message" />
                    </div>
                </div>

                <DouaneVignet />
                <VoertuigGekeurd />

                <!-- Comments Textarea -->
                <div class="row">
                    <div class="col-12">
                        <label v-if="isNL" for="message">Opmerkingen</label>
                        <label v-if="isFR" for="message">Commentaires</label>
                        <textarea name="opmerkingen" class="form-control"></textarea>
                    </div>
                </div>

                <!-- Upload PDF's-->
                <!-- <div class="row pb-5">
                    <div class="col-md-4">
                        <UploadPdf file_id="Buitenlandsinschrijvingsbewijs" title="Buitenlandsinschrijvingsbewijs" />
                    </div>
                    <div class="col-md-4">
                        <UploadPdf file_id="Gelijkvormigheidsattest" title="Gelijkvormigheidsattest (COC)" />
                    </div>
                    <div class="col-md-4">
                        <UploadPdf file_id="Douanevignet" title="E705 Douanevignet" />
                    </div>
                </div> -->

                <hr>
                <div class="row">
                    <div class="col-12">
                        <div class="form-group large-checkbox">
                            <input type="checkbox" id="termsCheckbox" v-model="acceptTerms" />
                            <label  v-if="isNL" class="ms-2" @click="openTermsModal">Ik aanvaard de <a>algemene voorwaarden</a> van RegiDrive</label>
                            <label  v-if="isFR" class="ms-2" @click="openTermsModal">J'accepte les <a>conditions générales</a> de RegiDrive</label>
                        </div>
                    </div>
                </div>
                <div class="row pb-5">
                    <div class="col-12">
                        <div class="form-group large-checkbox">
                            <input type="checkbox" id="privacyCheckbox" v-model="acceptPrivacy" />
                            <label v-if="isNL" class="ms-2" @click="openPrivacyModal">Ik aanvaard de <a>privacybeleid</a></label>
                            <label v-if="isFR" class="ms-2" @click="openPrivacyModal">J'accepte la <a>politique de confidentialité</a></label>
                        </div>
                    </div>
                </div>
                <!-- Submit Button -->
                <div class="row">
                    <div class="col-md-12 text-center">
                        <button  v-if="isNL" class="btn btn-primary btn-lg mt-3" :disabled="!canSubmit" type="submit">Gratis offerte</button>
                        <button  v-if="isFR" class="btn btn-primary btn-lg mt-3" :disabled="!canSubmit" type="submit">Devis Gratuit</button>
                    </div>
                </div>
            </Form>
        </div>
        <TermsDialog :show="showTermsModal" @close="showTermsModal = false" />
        <PrivacyDialog :show="showPrivacyModal" @close="showPrivacyModal = false" />
    </section>
</template>
  
<script>
import Entrepreneur from '@/components/Preregister/Entrepreneur.vue';
import Particulier from '@/components/Preregister/Particulier.vue';
import EntrepreneurFR from '@/components/Preregister/EntrepreneurFR.vue';
import ParticulierFR from '@/components/Preregister/ParticulierFR.vue';
import DouaneVignet from '@/components/Preregister/DouaneVignet.vue';
import ConformityCertificate from '@/components/Preregister/ConformityCertificate.vue';
import RegistrationCertificate from '@/components/Preregister/RegistrationCertificate.vue';
import TypeVehicle from '@/components/Preregister/TypeVehicle.vue';
import VoertuigGekeurd from '@/components/Preregister/VoertuigGekeurd.vue';
import TypeService from '@/components/Preregister/TypeService.vue';
import UploadPdf from '@/components/Preregister/UploadPdf.vue';

import TermsDialog from '@/components/Preregister/TermsDialog.vue';
import PrivacyDialog from '@/components/Preregister/PrivacyDialog.vue';

import { Field, Form, ErrorMessage, configure  } from 'vee-validate';


export default {
    components: {
        Entrepreneur,
        Particulier,
        EntrepreneurFR,
        ParticulierFR,
        DouaneVignet,
        RegistrationCertificate,
        ConformityCertificate,
        TypeVehicle,
        TypeService,
        VoertuigGekeurd,
        UploadPdf,
        TermsDialog,
        PrivacyDialog,
        Field,
        Form,
        ErrorMessage
    },
    data() {
        return {
            showTermsModal: false,
            showPrivacyModal: false,
            selectedType: 'Particulier',
            douanevignet: '',
            voertuiggekeurd: '',
            lastinspectiondate: null,
            typeService: '',
            acceptTerms: false,
            acceptPrivacy: false,
            datumInschrijving: '',
            euCountriesNL: [
                'België', 'Bulgarije', 'Cyprus', 'Denemarken',
                'Duitsland', 'Estland', 'Finland', 'Frankrijk',
                'Griekenland', 'Hongarije', 'Ierland', 'Italië',
                'Kroatië', 'Letland', 'Litouwen', 'Luxemburg',
                'Malta', 'Nederland', 'Oostenrijk', 'Polen',
                'Portugal', 'Roemenië', 'Slovenië', 'Slowakije',
                'Spanje', 'Tsjechië', 'Zweden'
            ],
            euCountriesFR: [
                'Belgique', 'Bulgarie', 'Chypre', 'Danemark',
                'Allemagne', 'Estonie', 'Finlande', 'France',
                'Grèce', 'Hongrie', 'Irlande', 'Italie',
                'Croatie', 'Lettonie', 'Lituanie', 'Luxembourg',
                'Malte', 'Pays-Bas', 'Autriche', 'Pologne',
                'Portugal', 'Roumanie', 'Slovénie', 'Slovaquie',
                'Espagne', 'Tchéquie', 'Suède'
            ],
            selectedCountry: ''
        };
    },
    computed: {
        redirectLocation() {
            return window.location.origin + '/formsubmitted';
        },
        formAction() {
            return this.$store.getters.formAction;
        },
        url() {
            return this.$store.getters.url;
        },
        canSubmit() {
            return this.acceptTerms && this.acceptPrivacy;
        },
        currentLanguage() {
            return this.$store.getters['languageStore/currentLanguage'];
        },
        isFR(){
          return this.currentLanguage == "FR";
        },
        isNL(){
          return this.currentLanguage == "NL";
        },
        generateMessage() {
            return (context) => {
                const isNL = this.currentLanguage === 'NL';
                const customMessages = {
                    required: isNL ? 'Dit veld is vereist' : 'Ce champ est obligatoire',
                    valid_chassis_number: isNL ? 'Chassisnummer moet 17 tekens lang zijn en zowel letters als cijfers bevatten' : 'Le numéro de châssis doit comporter 17 caractères et contenir à la fois des lettres et des chiffres',
                    // Add other custom messages here
                };

                const message = customMessages[context.rule.name];
                return message || (isNL ? `Het veld ${context.field} is vereist` : `Le champ ${context.field} est obligatoire`);
            }
        }
    },
    created() {
        configure({ generateMessage: this.generateMessage });
    },
    methods: {
        openTermsModal() {
            this.showTermsModal = true;
        },
        openPrivacyModal() {
            this.showPrivacyModal = true;
        }
    }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/variables.scss';

.preregister-section {
    min-height: 95vh;

    .heading {
        height: 20vh;
        background-color: $regi-dark;
        width: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-attachment: scroll;
        background-size: cover;
    }

    textarea {
        min-height: 200px;
        margin-bottom: 5rem;
    }

    .large-checkbox input[type="checkbox"] {
        transform: scale(1.5);
        margin-right: 5px;
    }

    .large-checkbox {
        input,
        label {
            cursor: pointer;
        }

        .ms-2 a{
            border-bottom: 1px solid #8a7741;
        }
    }
}
</style>