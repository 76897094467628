import 'bootstrap';
import './assets/styles/_variables.scss';
import './assets/styles/_global.scss';
import { createApp } from 'vue'
import { Field, Form, ErrorMessage, defineRule, configure } from 'vee-validate';
import { required, email } from '@vee-validate/rules';
import './utils/validationRules';
import App from './App.vue'
import router from './router'
import store from './store'

const app = createApp(App);

// Define global validation rules
defineRule('required', required);
defineRule('email', email);

// Register VeeValidate components globally
app.component('Field', Field);
app.component('Form', Form);
app.component('ErrorMessage', ErrorMessage);

createApp(App).use(store).use(router).mount('#app')
